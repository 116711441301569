const LocationReviewsData = [
    {
        "date": "2024-09-05",
        "review_rate": 5.0,
        "customer_name": "Christine Holt",
        "service_type": "Garage Door Insulation",
        "tech_name": "Vincent B.",
        "review": "This company has set the standard for garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-02-02",
        "review_rate": 4.9,
        "customer_name": "Robert Anderson",
        "service_type": "Garage Door Installation",
        "tech_name": "Anthony C.",
        "review": "I was surprised by how fast the technician completed the work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-07-11",
        "review_rate": 4.9,
        "customer_name": "Mary Brown",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Olivia D.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-04-05",
        "review_rate": 4.8,
        "customer_name": "David Rodriguez",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Krystal J.",
        "review": "I appreciated the straightforward communication during my service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-09-25",
        "review_rate": 5.0,
        "customer_name": "Natalie Brock",
        "service_type": "Garage Door Installation",
        "tech_name": "Luis H.",
        "review": "I can't express how satisfied I am with the garage door installation process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-08-31",
        "review_rate": 4.9,
        "customer_name": "Christina Phillips",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Stephanie G.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-05-24",
        "review_rate": 4.8,
        "customer_name": "Patricia Garcia",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Amy L.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-02-24",
        "review_rate": 5.0,
        "customer_name": "Michael Miller",
        "service_type": "Garage Door Off Track",
        "tech_name": "Steven H.",
        "review": "I appreciated the straightforward communication during my service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-07-23",
        "review_rate": 4.8,
        "customer_name": "Jacob Ross",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Andrew O.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2023-12-20",
        "review_rate": 4.9,
        "customer_name": "Alexandra Adams",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Melissa G.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-05-01",
        "review_rate": 4.9,
        "customer_name": "Terry Davis",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Brandon P.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-03-03",
        "review_rate": 4.9,
        "customer_name": "Latoya Elliott",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Caroline H.",
        "review": "Their attention to detail made all the difference in the outcome. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-07-10",
        "review_rate": 4.8,
        "customer_name": "Ariel Vega",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Robert M.",
        "review": "I would rate their service as the best I've ever had for garage work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-05-05",
        "review_rate": 4.8,
        "customer_name": "James Williams",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Cheryl R.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2023-11-26",
        "review_rate": 4.9,
        "customer_name": "Catherine Mills",
        "service_type": "Garage Door Installation",
        "tech_name": "Victor G.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-02-13",
        "review_rate": 5.0,
        "customer_name": "Michael Lewis",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Rebecca G.",
        "review": "I appreciated the thorough explanation of the work being done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2023-09-26",
        "review_rate": 5.0,
        "customer_name": "Connor Taylor",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Jill T.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-02-29",
        "review_rate": 4.9,
        "customer_name": "Kyle Walters",
        "service_type": "Garage Door Opener",
        "tech_name": "Christina R.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-06-19",
        "review_rate": 4.8,
        "customer_name": "William Jacobs",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "William C.",
        "review": "They handled my garage door issues with expertise and care. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-08-09",
        "review_rate": 4.9,
        "customer_name": "Roy Keller",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Melissa M.",
        "review": "I felt confident in their abilities after seeing the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-01-08",
        "review_rate": 5.0,
        "customer_name": "Samantha Sanchez",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Matthew S.",
        "review": "I felt reassured by their knowledge and experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-06-07",
        "review_rate": 4.8,
        "customer_name": "Charles Reyes",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Amy S.",
        "review": "The whole process felt easy and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2023-09-30",
        "review_rate": 4.9,
        "customer_name": "Meghan King",
        "service_type": "Garage Door Installation",
        "tech_name": "Xavier W.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-03-11",
        "review_rate": 4.8,
        "customer_name": "Deborah Shepherd",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "James W.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2023-12-27",
        "review_rate": 4.8,
        "customer_name": "Jessica Floyd",
        "service_type": "Garage Door Services",
        "tech_name": "Sherry L.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2023-12-01",
        "review_rate": 4.8,
        "customer_name": "Jessica Hernandez",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Elizabeth T.",
        "review": "The whole process felt easy and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2023-09-27",
        "review_rate": 4.9,
        "customer_name": "Theresa Ford",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Bryan V.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-08-01",
        "review_rate": 4.9,
        "customer_name": "Connie Bennett",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Laura R.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-02-26",
        "review_rate": 5.0,
        "customer_name": "Monica Kelly",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Brianna M.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-02-29",
        "review_rate": 5.0,
        "customer_name": "Kelly Moore MD",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Rick W.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-07-04",
        "review_rate": 5.0,
        "customer_name": "Anna Steele",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Amy M.",
        "review": "I was surprised by how fast the technician completed the work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-08-23",
        "review_rate": 4.8,
        "customer_name": "Louis Rogers",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Sean B.",
        "review": "The technician was courteous and very knowledgeable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2023-11-25",
        "review_rate": 5.0,
        "customer_name": "Alex Hill",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Rachel W.",
        "review": "My garage door never worked better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-03-22",
        "review_rate": 4.9,
        "customer_name": "Ashley Taylor",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Mark P.",
        "review": "The follow-up service was just as excellent as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-05-30",
        "review_rate": 4.8,
        "customer_name": "Kenneth Eaton",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Laura W.",
        "review": "I had an emergency with my garage door, and they responded quickly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-04-21",
        "review_rate": 5.0,
        "customer_name": "Scott Smith",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Christopher R.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-05-05",
        "review_rate": 4.9,
        "customer_name": "Travis Edwards",
        "service_type": "Garage Door Opener",
        "tech_name": "Mercedes F.",
        "review": "The technician made sure I understood all my options. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-05-28",
        "review_rate": 5.0,
        "customer_name": "Steven Phillips",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Toni T.",
        "review": "The process was straightforward and hassle-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-02-28",
        "review_rate": 5.0,
        "customer_name": "Heather Taylor",
        "service_type": "Garage Door Services",
        "tech_name": "Eric C.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-04-30",
        "review_rate": 4.9,
        "customer_name": "John Park",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Joseph B.",
        "review": "I appreciate how they kept me informed throughout the repair process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-05-19",
        "review_rate": 4.9,
        "customer_name": "Mario Williams",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Michelle M.",
        "review": "Having my garage door repaired was a smooth experience thanks to the team. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-01-13",
        "review_rate": 5.0,
        "customer_name": "Thomas Fox",
        "service_type": "Garage Door Repair",
        "tech_name": "Kayla H.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-07-07",
        "review_rate": 4.8,
        "customer_name": "Martha Sanchez",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Patrick S.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-03-17",
        "review_rate": 5.0,
        "customer_name": "Kimberly Robertson",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Alyssa C.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2023-11-21",
        "review_rate": 4.9,
        "customer_name": "Terri Hale",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Heidi D.",
        "review": "I would recommend them to anyone needing garage door services. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-08-02",
        "review_rate": 5.0,
        "customer_name": "Gloria Adams",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Mary M.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2023-10-14",
        "review_rate": 5.0,
        "customer_name": "Denise Jacobs",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Jennifer J.",
        "review": "I would rate their service as the best I've ever had for garage work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-07-29",
        "review_rate": 4.8,
        "customer_name": "Kelsey Robertson",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Maria N.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-01-05",
        "review_rate": 4.9,
        "customer_name": "Trevor Shaw",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Tommy H.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-08-28",
        "review_rate": 4.9,
        "customer_name": "Carla Mejia",
        "service_type": "Garage Door Insulation",
        "tech_name": "Jenny M.",
        "review": "I felt valued as a customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-05-27",
        "review_rate": 4.9,
        "customer_name": "Lori Hooper",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Rachel T.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-10-01",
        "review_rate": 4.9,
        "customer_name": "Eric Watkins",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Adriana H.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-06-08",
        "review_rate": 4.9,
        "customer_name": "Matthew Johnson",
        "service_type": "Garage Door Insulation",
        "tech_name": "Wendy P.",
        "review": "I can't recommend this garage door service enough after my experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-01-02",
        "review_rate": 4.8,
        "customer_name": "Carolyn Jackson",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Mary S.",
        "review": "The follow-up service was just as excellent as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-07-28",
        "review_rate": 4.9,
        "customer_name": "Kristina Ferguson",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Joe S.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-12-10",
        "review_rate": 4.8,
        "customer_name": "Amanda Roberts",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Wanda M.",
        "review": "I felt reassured by their knowledge and experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-07-24",
        "review_rate": 4.9,
        "customer_name": "Aaron Richardson",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Colleen C.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-12-11",
        "review_rate": 4.9,
        "customer_name": "Jason Sawyer",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Scott B.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-12-31",
        "review_rate": 4.8,
        "customer_name": "Stacey Peck",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Raymond P.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-03-01",
        "review_rate": 5.0,
        "customer_name": "Ashlee Andrade",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Linda G.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-10-16",
        "review_rate": 4.9,
        "customer_name": "Amy Rich",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Mark W.",
        "review": "I\u2019m very grateful for their outstanding customer support. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-09-21",
        "review_rate": 5.0,
        "customer_name": "David Alexander",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Donald T.",
        "review": "The technician made sure I understood all my options. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-07-09",
        "review_rate": 4.8,
        "customer_name": "Mark Kim",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Ronald W.",
        "review": "The service felt personal and catered to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-11-03",
        "review_rate": 4.9,
        "customer_name": "Erin Vaughn",
        "service_type": "Garage Door Opener",
        "tech_name": "Janice P.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-10-28",
        "review_rate": 4.9,
        "customer_name": "Calvin Alvarez",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Jose K.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-11-04",
        "review_rate": 4.9,
        "customer_name": "Kimberly Young",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Dawn G.",
        "review": "I was highly impressed with their professionalism throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-10-02",
        "review_rate": 5.0,
        "customer_name": "Terry Hurst",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Logan K.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-11-14",
        "review_rate": 4.9,
        "customer_name": "Timothy Glenn",
        "service_type": "Garage Door Maintenance",
        "tech_name": "William D.",
        "review": "The crew was friendly and very efficient with the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-12-30",
        "review_rate": 4.9,
        "customer_name": "Elizabeth Lawrence",
        "service_type": "Garage Door Services",
        "tech_name": "Mark R.",
        "review": "I was grateful for their quick response to my garage door emergency. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-04-13",
        "review_rate": 4.8,
        "customer_name": "Jose Harvey",
        "service_type": "Garage Door Installation",
        "tech_name": "John R.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-09-06",
        "review_rate": 4.9,
        "customer_name": "April West",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Richard H.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-05-08",
        "review_rate": 4.9,
        "customer_name": "Gabrielle Rogers",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Emily W.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-06-21",
        "review_rate": 4.9,
        "customer_name": "Yesenia Combs",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Emily M.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-04-13",
        "review_rate": 5.0,
        "customer_name": "Kelly Kline",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "John L.",
        "review": "I highly value the quick turnaround for my garage door repairs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-08-07",
        "review_rate": 4.8,
        "customer_name": "Emily Morgan",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Christian J.",
        "review": "The technician was friendly and professional at all times. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-11-09",
        "review_rate": 4.9,
        "customer_name": "Keith Johnson",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Dustin S.",
        "review": "I can't believe how much better my garage door operates now. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-04-05",
        "review_rate": 4.9,
        "customer_name": "Kimberly Barrett MD",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Gregory G.",
        "review": "It was reassuring to see the technician\u2019s expertise in action. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-01-06",
        "review_rate": 5.0,
        "customer_name": "Thomas Davis",
        "service_type": "Garage Door Repair",
        "tech_name": "Jennifer R.",
        "review": "I was highly impressed with their professionalism throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-10-11",
        "review_rate": 5.0,
        "customer_name": "Angela Harrison",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Ronald A.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-07-08",
        "review_rate": 4.9,
        "customer_name": "Denise Hernandez",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Faith B.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-06-30",
        "review_rate": 5.0,
        "customer_name": "Troy Forbes",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Maria F.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-12-01",
        "review_rate": 5.0,
        "customer_name": "Ashley Velez",
        "service_type": "Garage Door Repair",
        "tech_name": "Bryan E.",
        "review": "I appreciated the thorough explanation of the work being done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-12-31",
        "review_rate": 4.9,
        "customer_name": "Eric Mcdonald",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Sherry M.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-08-12",
        "review_rate": 4.9,
        "customer_name": "Derek Smith PhD",
        "service_type": "Garage Door Installation",
        "tech_name": "Kenneth G.",
        "review": "Having my garage door repaired was a smooth experience thanks to the team. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-07-22",
        "review_rate": 4.9,
        "customer_name": "Tamara Owens",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Kim A.",
        "review": "I was blown away by how quickly the technician fixed my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-03-15",
        "review_rate": 4.9,
        "customer_name": "Erin Sherman",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Eric G.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-09-11",
        "review_rate": 5.0,
        "customer_name": "Lucas Graham",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Daniel P.",
        "review": "I would rate their service as the best I've ever had for garage work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-08-21",
        "review_rate": 4.9,
        "customer_name": "Sarah Joseph",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Heather F.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-02-27",
        "review_rate": 5.0,
        "customer_name": "Shannon Wagner",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Carol M.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-06-21",
        "review_rate": 4.9,
        "customer_name": "Alicia Perry",
        "service_type": "Garage Door Installation",
        "tech_name": "Juan L.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-05-29",
        "review_rate": 4.9,
        "customer_name": "Christine Park",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Scott W.",
        "review": "I loved how the team worked seamlessly to get the job done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-07-07",
        "review_rate": 5.0,
        "customer_name": "Julia Wilson",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Carrie P.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-11-10",
        "review_rate": 4.9,
        "customer_name": "Sarah Saunders",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Jodi J.",
        "review": "I highly value the quick turnaround for my garage door repairs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-07-27",
        "review_rate": 4.8,
        "customer_name": "Elizabeth Carlson",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Anthony V.",
        "review": "My garage door issue was resolved quickly and professionally. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-02-24",
        "review_rate": 4.8,
        "customer_name": "Erica Miller",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Jonathan M.",
        "review": "I appreciated the straightforward communication during my service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-10-30",
        "review_rate": 4.8,
        "customer_name": "Brandi Mcgee",
        "service_type": "Garage Door Repair",
        "tech_name": "Robert K.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-08-23",
        "review_rate": 4.9,
        "customer_name": "Christopher Arnold",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Brittany C.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-04-23",
        "review_rate": 4.8,
        "customer_name": "Amanda Long",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Patricia W.",
        "review": "I can't recommend this garage door service enough after my experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-03-31",
        "review_rate": 4.9,
        "customer_name": "Michelle Contreras",
        "service_type": "Garage Door Services",
        "tech_name": "Clifford F.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-08-19",
        "review_rate": 4.9,
        "customer_name": "Eric Jones",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Brittany D.",
        "review": "The service felt personal and catered to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-03-26",
        "review_rate": 4.9,
        "customer_name": "Kim Martin",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Joshua L.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-08-28",
        "review_rate": 4.8,
        "customer_name": "Martin Holloway",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Michael D.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-11-24",
        "review_rate": 4.9,
        "customer_name": "Julia Foster",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Julie L.",
        "review": "My garage door issue was resolved quickly and professionally. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-12-16",
        "review_rate": 4.8,
        "customer_name": "Diane Nguyen",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Amanda B.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-07-19",
        "review_rate": 4.9,
        "customer_name": "Samuel Wilson",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Tammy K.",
        "review": "The technician was friendly and professional at all times. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-08-18",
        "review_rate": 4.9,
        "customer_name": "Jessica Quinn",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Jennifer B.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-12-17",
        "review_rate": 5.0,
        "customer_name": "Michael Cole",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Anthony M.",
        "review": "The crew was friendly and very efficient with the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-05-27",
        "review_rate": 4.9,
        "customer_name": "Melanie Martinez",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Martha J.",
        "review": "I can't believe how much better my garage door operates now. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-12-22",
        "review_rate": 4.8,
        "customer_name": "Fred Johnson",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Valerie T.",
        "review": "Their attention to detail made all the difference in the outcome. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-08-07",
        "review_rate": 4.8,
        "customer_name": "Stephen Lowe",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "David R.",
        "review": "The technician's expertise was evident throughout the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-04-23",
        "review_rate": 5.0,
        "customer_name": "Deborah Johnson",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Katie J.",
        "review": "It was reassuring to see the technician\u2019s expertise in action. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-01-02",
        "review_rate": 4.8,
        "customer_name": "Joshua Padilla Jr.",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Andrea S.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-07-01",
        "review_rate": 4.9,
        "customer_name": "Michael Barnes",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Kenneth R.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-04-11",
        "review_rate": 5.0,
        "customer_name": "Susan Watson",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Lauren M.",
        "review": "This company has set the standard for garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-09-21",
        "review_rate": 4.9,
        "customer_name": "Tanya James",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Evelyn W.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-02-05",
        "review_rate": 4.9,
        "customer_name": "Veronica Jackson",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Steven H.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-02-01",
        "review_rate": 4.9,
        "customer_name": "Brandon Sharp",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Billy C.",
        "review": "My garage door issue was resolved quickly and professionally. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-08-30",
        "review_rate": 4.9,
        "customer_name": "Evan Travis",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Ashley B.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-05-31",
        "review_rate": 4.9,
        "customer_name": "Amanda Jordan",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Beth T.",
        "review": "I felt confident in their abilities after seeing the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-09-12",
        "review_rate": 4.8,
        "customer_name": "Vanessa Huffman",
        "service_type": "Garage Door Opener",
        "tech_name": "Michael P.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-12-31",
        "review_rate": 5.0,
        "customer_name": "Carl Andrews",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Elizabeth A.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-02-02",
        "review_rate": 4.9,
        "customer_name": "William Carlson",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Connie T.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-05-15",
        "review_rate": 4.9,
        "customer_name": "Jeffrey Mcdonald",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Martha B.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-12-14",
        "review_rate": 4.9,
        "customer_name": "Donald Clark",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Jill D.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-05-26",
        "review_rate": 4.9,
        "customer_name": "Donna Watson",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Tyler R.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-10-01",
        "review_rate": 5.0,
        "customer_name": "Erik Johnson",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Craig H.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-02-09",
        "review_rate": 5.0,
        "customer_name": "David Bryant",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Michael A.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-05-21",
        "review_rate": 5.0,
        "customer_name": "Omar Stephenson",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Victor L.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-12-06",
        "review_rate": 4.9,
        "customer_name": "Adam Phillips",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Elizabeth G.",
        "review": "I was highly impressed with their professionalism throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-04-18",
        "review_rate": 4.8,
        "customer_name": "Adam Zuniga",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Jack A.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-08-26",
        "review_rate": 4.8,
        "customer_name": "Jeffrey Hanson",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Amy T.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-12-18",
        "review_rate": 4.8,
        "customer_name": "Diane Perez",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Peter C.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-01-16",
        "review_rate": 4.9,
        "customer_name": "Timothy Clark",
        "service_type": "Garage Door Installation",
        "tech_name": "Carolyn G.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-11-19",
        "review_rate": 4.8,
        "customer_name": "Elizabeth Miles",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Shawn H.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-07-24",
        "review_rate": 4.9,
        "customer_name": "Rebecca Duarte",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Brandi G.",
        "review": "My garage door now functions flawlessly, thanks to their expertise. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-12-04",
        "review_rate": 4.8,
        "customer_name": "Caitlin Jenkins",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Amanda W.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-08-23",
        "review_rate": 5.0,
        "customer_name": "Suzanne Bowen",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "John M.",
        "review": "This service was exactly what I needed for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-12-07",
        "review_rate": 4.9,
        "customer_name": "Randy Townsend MD",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Christine L.",
        "review": "Having my garage door repaired was a smooth experience thanks to the team. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-11-27",
        "review_rate": 4.9,
        "customer_name": "Ashley Wilson",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Joann M.",
        "review": "The technician was patient and understanding during the process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-08-17",
        "review_rate": 5.0,
        "customer_name": "Justin Wise",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Richard S.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-07-10",
        "review_rate": 4.9,
        "customer_name": "Christopher Villanueva",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Carly G.",
        "review": "The technician was friendly and professional at all times. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-04-18",
        "review_rate": 5.0,
        "customer_name": "Janet Butler",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Matthew O.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-11-26",
        "review_rate": 4.8,
        "customer_name": "April Eaton",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "James W.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-09-14",
        "review_rate": 4.8,
        "customer_name": "Leroy Case",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Donna B.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-01-03",
        "review_rate": 5.0,
        "customer_name": "Patrick King",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Frank H.",
        "review": "I highly value the quick turnaround for my garage door repairs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-01-08",
        "review_rate": 4.9,
        "customer_name": "Stephanie Bentley",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Robert L.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-05-25",
        "review_rate": 4.9,
        "customer_name": "Gregory Case",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Victor S.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-04-23",
        "review_rate": 4.9,
        "customer_name": "Jonathan Pope",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Francisco R.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-03-19",
        "review_rate": 4.9,
        "customer_name": "James Hood",
        "service_type": "Garage Door Opener",
        "tech_name": "Natasha H.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-10-15",
        "review_rate": 4.9,
        "customer_name": "Gabriela Williams",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Jasmine G.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-12-15",
        "review_rate": 4.9,
        "customer_name": "Courtney Lowe",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Chase N.",
        "review": "My garage door never worked better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-08-29",
        "review_rate": 4.9,
        "customer_name": "Joel Bradley",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Brandon B.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-01-21",
        "review_rate": 4.9,
        "customer_name": "Donald Hernandez",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Samantha W.",
        "review": "I was relieved to find such reliable service for my garage door issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-08-04",
        "review_rate": 4.9,
        "customer_name": "Gabrielle Sims",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Brandon K.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-01-26",
        "review_rate": 4.9,
        "customer_name": "Daniel Snyder",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Shannon H.",
        "review": "The crew was friendly and very efficient with the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-01-11",
        "review_rate": 4.8,
        "customer_name": "Samuel Daniels",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Tyler H.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-09-28",
        "review_rate": 4.8,
        "customer_name": "Zachary Watkins",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Krystal L.",
        "review": "I could tell they genuinely cared about their customers. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-11-03",
        "review_rate": 5.0,
        "customer_name": "Benjamin Parsons",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Angela R.",
        "review": "I was grateful for their quick response to my garage door emergency. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-04-27",
        "review_rate": 4.9,
        "customer_name": "Matthew Harris",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Debra C.",
        "review": "The technician was friendly and professional at all times. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-10-27",
        "review_rate": 4.9,
        "customer_name": "Christopher Price",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Samantha S.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-10-31",
        "review_rate": 5.0,
        "customer_name": "Christine Saunders",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Kristin B.",
        "review": "The technician took great care in performing the job correctly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-03-13",
        "review_rate": 4.8,
        "customer_name": "Pamela Schaefer",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Jeremy P.",
        "review": "My garage door never worked better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-11-07",
        "review_rate": 4.8,
        "customer_name": "Bradley Henry",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Sarah M.",
        "review": "I was thoroughly impressed with their garage door safety inspection. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-10-16",
        "review_rate": 4.9,
        "customer_name": "Nicole Lewis",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Jillian R.",
        "review": "The service felt personal and catered to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-11-17",
        "review_rate": 5.0,
        "customer_name": "David Thomas",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Mckenzie C.",
        "review": "I was blown away by how quickly the technician fixed my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-04-28",
        "review_rate": 4.9,
        "customer_name": "Edward Price",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Bruce S.",
        "review": "My garage door never worked better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-04-02",
        "review_rate": 5.0,
        "customer_name": "Bryan Andrews",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Alisha S.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-10-06",
        "review_rate": 4.8,
        "customer_name": "Joel Gutierrez",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Adam H.",
        "review": "I was grateful for their quick response to my garage door emergency. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-10-18",
        "review_rate": 4.9,
        "customer_name": "Eric Rose",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Meghan H.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-07-01",
        "review_rate": 4.9,
        "customer_name": "William Morris",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Daniel J.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-02-22",
        "review_rate": 5.0,
        "customer_name": "Melissa Gibson",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Amy F.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-10-12",
        "review_rate": 5.0,
        "customer_name": "Brian Silva",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Melissa M.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-10-30",
        "review_rate": 4.9,
        "customer_name": "Sarah Martin",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Jeremiah H.",
        "review": "I can't believe how much better my garage door operates now. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-02-19",
        "review_rate": 5.0,
        "customer_name": "Denise Martin",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Thomas H.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-11-26",
        "review_rate": 5.0,
        "customer_name": "Michael Holmes",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Justin P.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-08-08",
        "review_rate": 4.9,
        "customer_name": "Jill Doyle",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Erika G.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-11-24",
        "review_rate": 4.9,
        "customer_name": "Brenda Harris",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Anthony O.",
        "review": "The service was efficient and exceeded my expectations. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-01-29",
        "review_rate": 4.9,
        "customer_name": "Robert Brown",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Scott S.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-07-03",
        "review_rate": 4.9,
        "customer_name": "James Gomez",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Brian M.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-04-25",
        "review_rate": 4.9,
        "customer_name": "David Edwards",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Crystal M.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-09-16",
        "review_rate": 4.8,
        "customer_name": "Joel Martinez",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Mark M.",
        "review": "I felt reassured by their knowledge and experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-04-25",
        "review_rate": 4.9,
        "customer_name": "Cory Black",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Bethany O.",
        "review": "I appreciate how they kept me informed throughout the repair process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-06-07",
        "review_rate": 4.9,
        "customer_name": "David Williams",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Jessica W.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-04-16",
        "review_rate": 4.8,
        "customer_name": "Zachary Hatfield",
        "service_type": "Garage Door Opener",
        "tech_name": "Elizabeth W.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-11-28",
        "review_rate": 4.9,
        "customer_name": "Jeffrey Morgan",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Laura J.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-01-09",
        "review_rate": 4.9,
        "customer_name": "Taylor Smith",
        "service_type": "Garage Door Repair",
        "tech_name": "Christopher H.",
        "review": "I felt confident in their abilities after seeing the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-02-17",
        "review_rate": 4.8,
        "customer_name": "Paul Smith",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Annette S.",
        "review": "I can't believe how much better my garage door operates now. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-05-11",
        "review_rate": 5.0,
        "customer_name": "Tammy Castillo",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Jason J.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-10-28",
        "review_rate": 4.9,
        "customer_name": "Michael Kerr",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Ryan R.",
        "review": "It was reassuring to see the technician\u2019s expertise in action. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-05-30",
        "review_rate": 4.8,
        "customer_name": "Brandon Heath",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Emily S.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-11-14",
        "review_rate": 5.0,
        "customer_name": "Kelsey Dennis",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Amy M.",
        "review": "The technician's expertise was evident throughout the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-01-02",
        "review_rate": 5.0,
        "customer_name": "Danielle Brown",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Tiffany M.",
        "review": "I highly value the quick turnaround for my garage door repairs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-02-08",
        "review_rate": 4.9,
        "customer_name": "Brandy Jones",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Stephanie H.",
        "review": "The technician made sure I understood all my options. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-11-07",
        "review_rate": 4.8,
        "customer_name": "Mr. Matthew Peterson",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Felicia R.",
        "review": "My garage door never worked better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-03-24",
        "review_rate": 4.9,
        "customer_name": "Robert Wilson",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Dawn B.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-02-13",
        "review_rate": 4.9,
        "customer_name": "Mackenzie Patterson",
        "service_type": "Garage Door Opener",
        "tech_name": "Michael W.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-04-21",
        "review_rate": 4.9,
        "customer_name": "James Atkinson",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Karen R.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-01-02",
        "review_rate": 4.8,
        "customer_name": "Nicholas Johnson",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Curtis G.",
        "review": "I found their approach to customer service refreshing and effective. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-07-25",
        "review_rate": 5.0,
        "customer_name": "Megan Barker",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Marc V.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-09-25",
        "review_rate": 4.9,
        "customer_name": "Charles Collins",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Julia B.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-09-28",
        "review_rate": 5.0,
        "customer_name": "Christopher Rice",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Cristina W.",
        "review": "I would rate their service as the best I've ever had for garage work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-01-26",
        "review_rate": 4.9,
        "customer_name": "Philip Bryant",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Virginia F.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-09-05",
        "review_rate": 4.9,
        "customer_name": "Amanda Johnson",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Megan Z.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-10-29",
        "review_rate": 5.0,
        "customer_name": "Elizabeth Pope",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Melissa M.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-12-07",
        "review_rate": 4.8,
        "customer_name": "Sara Cook",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Laura S.",
        "review": "I can't believe how much better my garage door operates now. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-04-06",
        "review_rate": 4.9,
        "customer_name": "Richard Castro",
        "service_type": "Garage Door Services",
        "tech_name": "Kimberly B.",
        "review": "This service was exactly what I needed for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-09-22",
        "review_rate": 4.9,
        "customer_name": "Deanna Flores",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Cynthia F.",
        "review": "The technician was friendly and professional at all times. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-02-12",
        "review_rate": 4.9,
        "customer_name": "Steven Cooper",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Jared M.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-01-25",
        "review_rate": 5.0,
        "customer_name": "Ryan Castillo",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Melissa G.",
        "review": "I felt valued as a customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-02-08",
        "review_rate": 4.9,
        "customer_name": "Donald Woods",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Steven J.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-10-21",
        "review_rate": 4.9,
        "customer_name": "John Anderson",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Michael F.",
        "review": "The follow-up service was just as excellent as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-01-15",
        "review_rate": 4.8,
        "customer_name": "Benjamin Young",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Kelly H.",
        "review": "I could tell they genuinely cared about their customers. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-06-17",
        "review_rate": 4.9,
        "customer_name": "Steven Kelly",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Robin G.",
        "review": "I can't express how satisfied I am with the garage door installation process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-06-20",
        "review_rate": 5.0,
        "customer_name": "Chelsea Stone",
        "service_type": "Garage Door Track Repair",
        "tech_name": "David S.",
        "review": "I couldn\u2019t be more satisfied with my garage door experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-06-26",
        "review_rate": 4.9,
        "customer_name": "Christopher Bailey",
        "service_type": "Garage Door Track Repair",
        "tech_name": "James M.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-03-01",
        "review_rate": 4.9,
        "customer_name": "Sharon Conley",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Benjamin M.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-10-22",
        "review_rate": 4.9,
        "customer_name": "Paul Jones",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Leonard A.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-08-12",
        "review_rate": 5.0,
        "customer_name": "Caitlin Franco",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Kayla R.",
        "review": "The level of professionalism displayed was remarkable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-06-24",
        "review_rate": 4.8,
        "customer_name": "Laura Taylor",
        "service_type": "Garage Door Insulation",
        "tech_name": "Alec W.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-05-03",
        "review_rate": 4.9,
        "customer_name": "Amanda Reeves",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Julie B.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-01-05",
        "review_rate": 5.0,
        "customer_name": "Jeremiah Smith",
        "service_type": "Garage Door Insulation",
        "tech_name": "Jeffrey R.",
        "review": "The technician made sure I understood all my options. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-12-23",
        "review_rate": 4.9,
        "customer_name": "Benjamin Jones",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Brittany R.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-11-26",
        "review_rate": 4.8,
        "customer_name": "Katelyn Williams",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Shannon W.",
        "review": "I can't express how satisfied I am with the garage door installation process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-09-12",
        "review_rate": 4.9,
        "customer_name": "Sherry Fowler MD",
        "service_type": "Garage Door Installation",
        "tech_name": "Angela D.",
        "review": "I loved how the team worked seamlessly to get the job done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-08-12",
        "review_rate": 4.9,
        "customer_name": "Janice Bennett",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Larry C.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-11-10",
        "review_rate": 4.9,
        "customer_name": "Gordon Jones",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Nancy W.",
        "review": "I was blown away by their level of expertise and service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-01-30",
        "review_rate": 4.9,
        "customer_name": "Shane Alvarez",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Summer H.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-02-27",
        "review_rate": 4.9,
        "customer_name": "Ashley Smith",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Pamela R.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-07-18",
        "review_rate": 5.0,
        "customer_name": "Jennifer Stone",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Stephanie P.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-05-15",
        "review_rate": 4.9,
        "customer_name": "Nicholas Shaw",
        "service_type": "Garage Door Off Track",
        "tech_name": "Patricia M.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-04-12",
        "review_rate": 4.8,
        "customer_name": "Kristin Martinez",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Daniel Z.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-07-28",
        "review_rate": 4.9,
        "customer_name": "Sabrina Martin",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Michael D.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-02-04",
        "review_rate": 4.9,
        "customer_name": "Taylor Gomez",
        "service_type": "Garage Door Off Track",
        "tech_name": "Jennifer O.",
        "review": "This company has set the standard for garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-09-14",
        "review_rate": 4.9,
        "customer_name": "William Ryan",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Michael P.",
        "review": "The service was efficient and exceeded my expectations. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-07-07",
        "review_rate": 4.9,
        "customer_name": "Janet Flynn",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Joseph M.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-02-03",
        "review_rate": 4.9,
        "customer_name": "Jessica Gibbs",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Joshua T.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-02-24",
        "review_rate": 4.8,
        "customer_name": "Mark Velasquez",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Chad B.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-04-05",
        "review_rate": 4.9,
        "customer_name": "Megan Warren",
        "service_type": "Garage Door Installation",
        "tech_name": "Danielle W.",
        "review": "The crew was friendly and very efficient with the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-11-06",
        "review_rate": 4.9,
        "customer_name": "Joshua Harris",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Jennifer B.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-07-28",
        "review_rate": 4.9,
        "customer_name": "Vincent Molina",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Jessica B.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-06-12",
        "review_rate": 4.9,
        "customer_name": "David Porter",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Julie J.",
        "review": "I can't express how satisfied I am with the garage door installation process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-06-18",
        "review_rate": 5.0,
        "customer_name": "Joseph Russell",
        "service_type": "Garage Door Services",
        "tech_name": "Jeffrey R.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-09-26",
        "review_rate": 4.9,
        "customer_name": "Catherine Delacruz DDS",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Alison W.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-03-09",
        "review_rate": 4.8,
        "customer_name": "Lorraine Hart",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Michael S.",
        "review": "I highly value the quick turnaround for my garage door repairs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-02-13",
        "review_rate": 5.0,
        "customer_name": "Gary Lawson",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Kristina K.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-07-26",
        "review_rate": 5.0,
        "customer_name": "Alex Hawkins",
        "service_type": "Garage Door Off Track",
        "tech_name": "Philip C.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-07-19",
        "review_rate": 5.0,
        "customer_name": "Sandra Anderson",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Shirley B.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-11-20",
        "review_rate": 4.9,
        "customer_name": "Cynthia Valdez",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Michael M.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-03-13",
        "review_rate": 4.8,
        "customer_name": "Kevin Jones",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Robin B.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-07-10",
        "review_rate": 5.0,
        "customer_name": "Heather West",
        "service_type": "Garage Door Off Track",
        "tech_name": "Micheal S.",
        "review": "The quality of their work is evident in the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-07-20",
        "review_rate": 4.8,
        "customer_name": "Maria Frazier",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Jennifer S.",
        "review": "The technician made sure I understood all my options. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2023-10-28",
        "review_rate": 4.8,
        "customer_name": "Megan Hale",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "John J.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-08-16",
        "review_rate": 5.0,
        "customer_name": "Erica Day",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Alexis W.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-03-24",
        "review_rate": 4.8,
        "customer_name": "Antonio Avila",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Dawn A.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2023-10-15",
        "review_rate": 4.9,
        "customer_name": "Charles Lee",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Samantha G.",
        "review": "I felt confident in their abilities after seeing the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-03-25",
        "review_rate": 5.0,
        "customer_name": "Justin Ortega",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Mercedes C.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-03-14",
        "review_rate": 4.9,
        "customer_name": "Alexis Marshall",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Joseph W.",
        "review": "I would recommend them to anyone needing garage door services. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-03-24",
        "review_rate": 4.8,
        "customer_name": "Shane Parker",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Lynn C.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2023-11-01",
        "review_rate": 5.0,
        "customer_name": "Stacey Clark",
        "service_type": "Residential Garage Door Services",
        "tech_name": "John G.",
        "review": "My garage door never worked better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-03-28",
        "review_rate": 5.0,
        "customer_name": "Richard Johnson",
        "service_type": "Garage Door Opener",
        "tech_name": "Shelby M.",
        "review": "Having my garage door repaired was a smooth experience thanks to the team. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-07-13",
        "review_rate": 4.8,
        "customer_name": "Justin Barnes",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "John H.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-07-27",
        "review_rate": 4.9,
        "customer_name": "Heidi Gregory",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Kristen Y.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-06-15",
        "review_rate": 4.8,
        "customer_name": "Brittany Madden",
        "service_type": "Garage Door Services",
        "tech_name": "Angela W.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2023-10-10",
        "review_rate": 4.8,
        "customer_name": "Gary Brooks",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Jenna G.",
        "review": "I can't recommend this garage door service enough after my experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-01-11",
        "review_rate": 4.9,
        "customer_name": "Manuel Martinez",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Luis R.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2023-12-01",
        "review_rate": 5.0,
        "customer_name": "John Wise",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Cassandra S.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-06-07",
        "review_rate": 4.9,
        "customer_name": "April Carter",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Linda R.",
        "review": "I felt confident in their abilities after seeing the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-03-05",
        "review_rate": 4.9,
        "customer_name": "Ryan Stanley",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Anthony W.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-01-17",
        "review_rate": 5.0,
        "customer_name": "Jerry Williams",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Stephanie D.",
        "review": "I can't express how satisfied I am with the garage door installation process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-01-13",
        "review_rate": 5.0,
        "customer_name": "Angel Stevenson",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Michael S.",
        "review": "I couldn\u2019t be more satisfied with my garage door experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-03-18",
        "review_rate": 4.8,
        "customer_name": "Susan Johnson",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Michael W.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2023-12-12",
        "review_rate": 5.0,
        "customer_name": "David Edwards",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Brittany R.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-06-15",
        "review_rate": 5.0,
        "customer_name": "Regina Hall",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Christopher M.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-03-04",
        "review_rate": 5.0,
        "customer_name": "Hayden White",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Curtis R.",
        "review": "I felt confident in their abilities after seeing the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-08-26",
        "review_rate": 4.8,
        "customer_name": "Yolanda Brewer",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Elizabeth E.",
        "review": "Their attention to detail made all the difference in the outcome. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-06-07",
        "review_rate": 4.9,
        "customer_name": "Casey Welch",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Gary B.",
        "review": "They handled my garage door issues with expertise and care. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-07-25",
        "review_rate": 4.9,
        "customer_name": "Thomas Smith",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Caitlin L.",
        "review": "I appreciated the thorough explanation of the work being done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-05-17",
        "review_rate": 4.9,
        "customer_name": "Crystal Cunningham",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Jasmine W.",
        "review": "I was grateful for their quick response to my garage door emergency. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2023-10-22",
        "review_rate": 5.0,
        "customer_name": "Amanda Smith",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Clayton J.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-02-18",
        "review_rate": 4.9,
        "customer_name": "Zachary Davidson",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Vanessa B.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-08-24",
        "review_rate": 4.9,
        "customer_name": "Dr. Kathryn Ochoa",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Andrea G.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-06-30",
        "review_rate": 4.9,
        "customer_name": "Aaron Patel",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "John R.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-06-24",
        "review_rate": 5.0,
        "customer_name": "Jordan Anderson",
        "service_type": "Garage Door Repair",
        "tech_name": "Lisa D.",
        "review": "They handled my garage door issues with expertise and care. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2023-10-01",
        "review_rate": 4.9,
        "customer_name": "Tina Douglas",
        "service_type": "Garage Door Opener",
        "tech_name": "Johnny H.",
        "review": "I felt confident in their abilities after seeing the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-01-23",
        "review_rate": 4.9,
        "customer_name": "Phillip Rodriguez",
        "service_type": "Garage Door Repair",
        "tech_name": "Jonathan H.",
        "review": "Having my garage door repaired was a smooth experience thanks to the team. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-02-01",
        "review_rate": 4.9,
        "customer_name": "Julian Bailey",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Evelyn B.",
        "review": "The follow-up service was just as excellent as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-07-27",
        "review_rate": 4.8,
        "customer_name": "Tyler Jenkins",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Noah S.",
        "review": "I appreciated the thorough explanation of the work being done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-01-30",
        "review_rate": 5.0,
        "customer_name": "Michelle Pham",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Sherri S.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-07-19",
        "review_rate": 4.8,
        "customer_name": "Cynthia Lawrence",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Brittany S.",
        "review": "I was blown away by their level of expertise and service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-09-06",
        "review_rate": 4.9,
        "customer_name": "Nicholas Smith",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Marie G.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-09-04",
        "review_rate": 5.0,
        "customer_name": "Jenny Wilson",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "John B.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2023-10-06",
        "review_rate": 4.8,
        "customer_name": "Bradley Page",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Matthew W.",
        "review": "My experience with their garage door service was overwhelmingly positive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2023-11-11",
        "review_rate": 4.9,
        "customer_name": "Karen Perez",
        "service_type": "Garage Door Maintenance",
        "tech_name": "William R.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-03-08",
        "review_rate": 4.9,
        "customer_name": "Kimberly Adkins",
        "service_type": "Garage Door Opener",
        "tech_name": "Todd S.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-01-05",
        "review_rate": 4.9,
        "customer_name": "David Flores",
        "service_type": "Garage Door Services",
        "tech_name": "Robert P.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-06-09",
        "review_rate": 4.9,
        "customer_name": "Chad White",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Kenneth D.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-03-30",
        "review_rate": 4.8,
        "customer_name": "Jodi Ruiz",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Brian L.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-02-15",
        "review_rate": 5.0,
        "customer_name": "Sarah Alexander",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Michael R.",
        "review": "I had an emergency with my garage door, and they responded quickly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-02-05",
        "review_rate": 4.8,
        "customer_name": "Crystal Scott",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Jeffrey F.",
        "review": "The technician's expertise was evident throughout the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-07-11",
        "review_rate": 5.0,
        "customer_name": "Stephanie Howe",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Ivan C.",
        "review": "The technician was patient and understanding during the process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-09-15",
        "review_rate": 5.0,
        "customer_name": "Randall Brooks",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Jorge A.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-07-25",
        "review_rate": 4.8,
        "customer_name": "Clifford Hogan",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Danielle C.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-01-26",
        "review_rate": 4.8,
        "customer_name": "Edward Andrews",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Amy C.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-07-17",
        "review_rate": 5.0,
        "customer_name": "Karl Vincent",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Danielle M.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-08-27",
        "review_rate": 4.8,
        "customer_name": "Christopher Williams",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Maria W.",
        "review": "The technician was courteous and very knowledgeable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-07-28",
        "review_rate": 4.9,
        "customer_name": "Holly Porter",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Mary J.",
        "review": "I was blown away by their level of expertise and service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-07-31",
        "review_rate": 4.9,
        "customer_name": "Luis Evans",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Timothy M.",
        "review": "I felt reassured by their knowledge and experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-10-18",
        "review_rate": 4.8,
        "customer_name": "Nicole Garcia",
        "service_type": "Garage Door Installation",
        "tech_name": "Melissa M.",
        "review": "I found their approach to customer service refreshing and effective. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-10-14",
        "review_rate": 4.9,
        "customer_name": "Charles Barrera",
        "service_type": "Garage Door Opener",
        "tech_name": "Jennifer S.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-10-15",
        "review_rate": 5.0,
        "customer_name": "Mr. Jason Stevens",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Adam S.",
        "review": "The technician's expertise was evident throughout the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-07-23",
        "review_rate": 4.9,
        "customer_name": "Tammy Rubio",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Terry H.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-04-24",
        "review_rate": 5.0,
        "customer_name": "John Bauer",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Hannah A.",
        "review": "The technician was friendly and professional at all times. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-05-09",
        "review_rate": 4.9,
        "customer_name": "Lisa Smith",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Daisy F.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-09-02",
        "review_rate": 4.9,
        "customer_name": "Elizabeth Anderson",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Renee C.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-07-08",
        "review_rate": 4.9,
        "customer_name": "Tammy Bernard",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Jonathan C.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-09-13",
        "review_rate": 4.8,
        "customer_name": "Zoe Middleton",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Matthew T.",
        "review": "I appreciated the straightforward communication during my service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-07-30",
        "review_rate": 4.9,
        "customer_name": "Robert Pierce",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Brandon H.",
        "review": "I would recommend them to anyone needing garage door services. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-09-30",
        "review_rate": 4.9,
        "customer_name": "Margaret Chung",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Veronica K.",
        "review": "I could tell they genuinely cared about their customers. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-04-28",
        "review_rate": 4.9,
        "customer_name": "Mike Martin",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Ronald W.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-10-07",
        "review_rate": 5.0,
        "customer_name": "Kristie Pittman DVM",
        "service_type": "Garage Door Services",
        "tech_name": "Amy R.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-07-06",
        "review_rate": 5.0,
        "customer_name": "Nicole West",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Rebecca F.",
        "review": "I would rate their service as the best I've ever had for garage work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-02-28",
        "review_rate": 4.9,
        "customer_name": "Amanda Bennett",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Emma B.",
        "review": "I was blown away by how quickly the technician fixed my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-11-02",
        "review_rate": 4.9,
        "customer_name": "Ashley Stephens",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Jonathan W.",
        "review": "I felt valued as a customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-07-09",
        "review_rate": 4.8,
        "customer_name": "Logan Ware",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Cathy H.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-09-06",
        "review_rate": 5.0,
        "customer_name": "Austin Anderson",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Russell H.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-06-24",
        "review_rate": 4.8,
        "customer_name": "Rebecca Allen",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Mary R.",
        "review": "The process was straightforward and hassle-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-10-31",
        "review_rate": 4.9,
        "customer_name": "Mark Davis",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Nicholas C.",
        "review": "I felt valued as a customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-03-17",
        "review_rate": 4.8,
        "customer_name": "Dustin Young",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Amber F.",
        "review": "My garage door issue was resolved quickly and professionally. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-03-15",
        "review_rate": 5.0,
        "customer_name": "Denise Murray",
        "service_type": "Garage Door Repair",
        "tech_name": "Brian N.",
        "review": "I was blown away by how quickly the technician fixed my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-03-18",
        "review_rate": 4.9,
        "customer_name": "Emily Warner",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Maria B.",
        "review": "The process was straightforward and hassle-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-03-28",
        "review_rate": 4.8,
        "customer_name": "Sara Bishop",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Zachary R.",
        "review": "It was reassuring to see the technician\u2019s expertise in action. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-01-25",
        "review_rate": 4.9,
        "customer_name": "Evan Nelson",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Joseph A.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-02-10",
        "review_rate": 5.0,
        "customer_name": "Brittney Smith",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Connie C.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-05-16",
        "review_rate": 4.8,
        "customer_name": "John Lucas",
        "service_type": "Garage Door Installation",
        "tech_name": "Todd P.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-11-11",
        "review_rate": 4.9,
        "customer_name": "Eugene Nash",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Phillip W.",
        "review": "I felt confident in their abilities after seeing the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-08-06",
        "review_rate": 4.9,
        "customer_name": "Julie Ramos",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Donna M.",
        "review": "I was grateful for their quick response to my garage door emergency. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-05-07",
        "review_rate": 4.9,
        "customer_name": "James Parker",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Kevin C.",
        "review": "The whole process felt easy and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-01-15",
        "review_rate": 4.9,
        "customer_name": "Christina Schroeder",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Elizabeth R.",
        "review": "The level of professionalism displayed was remarkable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-02-03",
        "review_rate": 4.9,
        "customer_name": "Sean Allen",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Megan S.",
        "review": "The technician made sure I understood all my options. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-08-20",
        "review_rate": 4.8,
        "customer_name": "Jesse Mason",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Maria M.",
        "review": "I can't express how satisfied I am with the garage door installation process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-09-25",
        "review_rate": 4.8,
        "customer_name": "Andrew Mendez",
        "service_type": "Garage Door Insulation",
        "tech_name": "Jessica H.",
        "review": "I was grateful for their quick response to my garage door emergency. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-06-10",
        "review_rate": 4.9,
        "customer_name": "Stanley Moore",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Michelle C.",
        "review": "I can't express how satisfied I am with the garage door installation process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-08-25",
        "review_rate": 4.9,
        "customer_name": "Lauren Garza",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Shirley M.",
        "review": "The technician made sure I understood all my options. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-02-19",
        "review_rate": 4.8,
        "customer_name": "Albert Roberts",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Teresa E.",
        "review": "I can't recommend this garage door service enough after my experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-01-13",
        "review_rate": 4.9,
        "customer_name": "Kari Reed",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Michael L.",
        "review": "I felt informed and confident about the repairs being made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-11-22",
        "review_rate": 4.9,
        "customer_name": "Jacqueline Stewart",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Cheryl M.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-06-30",
        "review_rate": 4.9,
        "customer_name": "Cody Kane",
        "service_type": "Garage Door Opener",
        "tech_name": "Jacqueline F.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-06-17",
        "review_rate": 4.9,
        "customer_name": "Ricky Patton",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Scott S.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-05-14",
        "review_rate": 4.9,
        "customer_name": "Carla Chavez",
        "service_type": "Garage Door Repair",
        "tech_name": "Sean F.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-10-12",
        "review_rate": 4.9,
        "customer_name": "David Williams",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Steven J.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-09-27",
        "review_rate": 5.0,
        "customer_name": "Thomas Perry",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Adam M.",
        "review": "I could tell they genuinely cared about their customers. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-02-09",
        "review_rate": 4.9,
        "customer_name": "Jeanette King",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Wendy S.",
        "review": "I was highly impressed with their professionalism throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-10-30",
        "review_rate": 4.8,
        "customer_name": "Jamie Reyes",
        "service_type": "Garage Door Insulation",
        "tech_name": "Antonio P.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-09-01",
        "review_rate": 4.9,
        "customer_name": "James Gibson",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Jeffery N.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-11-11",
        "review_rate": 4.9,
        "customer_name": "Courtney Harris",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Thomas K.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-04-24",
        "review_rate": 4.9,
        "customer_name": "Rebecca Vargas",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Laura H.",
        "review": "Having my garage door repaired was a smooth experience thanks to the team. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-10-13",
        "review_rate": 5.0,
        "customer_name": "Jessica Collins",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Caleb W.",
        "review": "The process was straightforward and hassle-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-04-01",
        "review_rate": 4.8,
        "customer_name": "Joseph Morris",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Jason R.",
        "review": "I appreciate how they kept me informed throughout the repair process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-08-02",
        "review_rate": 4.8,
        "customer_name": "Russell Hamilton",
        "service_type": "Garage Door Insulation",
        "tech_name": "Mercedes S.",
        "review": "I felt valued as a customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-04-29",
        "review_rate": 4.9,
        "customer_name": "Sarah Mendoza",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Frank B.",
        "review": "I would recommend them to anyone needing garage door services. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-01-01",
        "review_rate": 4.8,
        "customer_name": "Kevin Bailey",
        "service_type": "Garage Door Opener",
        "tech_name": "Brandon S.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-09-08",
        "review_rate": 4.8,
        "customer_name": "Julia Richardson",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Matthew L.",
        "review": "I can't recommend this garage door service enough after my experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-07-02",
        "review_rate": 4.9,
        "customer_name": "Christopher Brady",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Michelle M.",
        "review": "Their attention to detail made all the difference in the outcome. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-05-26",
        "review_rate": 4.9,
        "customer_name": "Lori Peterson",
        "service_type": "Garage Door Insulation",
        "tech_name": "James S.",
        "review": "My garage door now functions flawlessly, thanks to their expertise. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-02-12",
        "review_rate": 4.8,
        "customer_name": "Raymond Ortiz",
        "service_type": "Garage Door Insulation",
        "tech_name": "Daniel H.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-06-22",
        "review_rate": 4.9,
        "customer_name": "Jennifer Anderson",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Taylor B.",
        "review": "I felt informed and confident about the repairs being made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-05-23",
        "review_rate": 5.0,
        "customer_name": "James Smith",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Philip J.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-10-19",
        "review_rate": 4.9,
        "customer_name": "Grant Gross",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Crystal C.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-03-22",
        "review_rate": 4.9,
        "customer_name": "George Douglas",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Gregory C.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-01-20",
        "review_rate": 4.9,
        "customer_name": "Shannon Lloyd",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Tommy B.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-11-28",
        "review_rate": 4.8,
        "customer_name": "Cheryl Taylor",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Catherine H.",
        "review": "My garage door now functions flawlessly, thanks to their expertise. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-11-29",
        "review_rate": 4.9,
        "customer_name": "Megan Johnson",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Jessica M.",
        "review": "The quality of their work is evident in the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-12-26",
        "review_rate": 5.0,
        "customer_name": "Sharon Wang",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Brandon I.",
        "review": "I would recommend them to anyone needing garage door services. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-08-31",
        "review_rate": 4.9,
        "customer_name": "Joseph Cox",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Clarence J.",
        "review": "I couldn\u2019t be more satisfied with my garage door experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-02-02",
        "review_rate": 4.8,
        "customer_name": "Melissa Downs",
        "service_type": "Garage Door Services",
        "tech_name": "Shawn R.",
        "review": "The technician took great care in performing the job correctly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-03-12",
        "review_rate": 4.9,
        "customer_name": "Steven Thomas",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Michael G.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-04-01",
        "review_rate": 4.9,
        "customer_name": "Casey Johnson",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Karen T.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-05-04",
        "review_rate": 4.8,
        "customer_name": "Noah Cruz",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "James H.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-01-03",
        "review_rate": 4.9,
        "customer_name": "Crystal Smith",
        "service_type": "Garage Door Opener",
        "tech_name": "Tamara L.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-05-15",
        "review_rate": 4.9,
        "customer_name": "Melanie Pierce",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Robert D.",
        "review": "I\u2019m very grateful for their outstanding customer support. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-01-21",
        "review_rate": 4.9,
        "customer_name": "Virginia Carter",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Philip L.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-01-01",
        "review_rate": 4.9,
        "customer_name": "Harold Collins",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Paula G.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-03-24",
        "review_rate": 5.0,
        "customer_name": "Erin Ellis",
        "service_type": "Garage Door Insulation",
        "tech_name": "Michael L.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-07-05",
        "review_rate": 4.9,
        "customer_name": "Brenda Lyons",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Mackenzie M.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-11-02",
        "review_rate": 4.9,
        "customer_name": "Aaron Wong",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Dawn F.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-07-03",
        "review_rate": 4.9,
        "customer_name": "Mr. Frank Montgomery",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Travis B.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-09-01",
        "review_rate": 4.9,
        "customer_name": "Angela Gonzalez",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Christopher S.",
        "review": "The whole process felt easy and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-07-04",
        "review_rate": 4.9,
        "customer_name": "Michael Acosta",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Brandon R.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-01-11",
        "review_rate": 4.8,
        "customer_name": "Charles Roberts",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Christina G.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-03-07",
        "review_rate": 5.0,
        "customer_name": "Matthew Fleming",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Charles M.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-08-26",
        "review_rate": 4.9,
        "customer_name": "Amy Nguyen",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Kevin S.",
        "review": "It was reassuring to see the technician\u2019s expertise in action. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-11-01",
        "review_rate": 4.9,
        "customer_name": "Nicholas Morris",
        "service_type": "Garage Door Insulation",
        "tech_name": "Leah B.",
        "review": "Having my garage door repaired was a smooth experience thanks to the team. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-12-08",
        "review_rate": 5.0,
        "customer_name": "Ashley Mitchell",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Steven N.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-09-09",
        "review_rate": 5.0,
        "customer_name": "Heather Hutchinson",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Francis C.",
        "review": "The service felt personal and catered to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-09-07",
        "review_rate": 4.9,
        "customer_name": "Jonathan Cardenas",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Laura G.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-07-27",
        "review_rate": 4.9,
        "customer_name": "Amanda Romero",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Adam L.",
        "review": "My garage door now functions flawlessly, thanks to their expertise. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-12-12",
        "review_rate": 5.0,
        "customer_name": "Kimberly Pena",
        "service_type": "Garage Door Installation",
        "tech_name": "Rodney M.",
        "review": "I loved how the team worked seamlessly to get the job done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-10-20",
        "review_rate": 4.9,
        "customer_name": "Juan Caldwell",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Jason J.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-10-22",
        "review_rate": 4.9,
        "customer_name": "Denise Adams",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Arthur R.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-11-10",
        "review_rate": 5.0,
        "customer_name": "Catherine Oliver",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Pamela W.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-07-25",
        "review_rate": 4.9,
        "customer_name": "Kelly Jones",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Paul R.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-12-08",
        "review_rate": 4.9,
        "customer_name": "Mary Wise",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "George D.",
        "review": "I can't recommend this garage door service enough after my experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-03-13",
        "review_rate": 4.9,
        "customer_name": "Jonathan Fuentes",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Elizabeth M.",
        "review": "I highly value the quick turnaround for my garage door repairs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-11-05",
        "review_rate": 4.9,
        "customer_name": "Gail Fry",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Colleen C.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-12-20",
        "review_rate": 5.0,
        "customer_name": "Christine Wilson",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Miranda N.",
        "review": "I was relieved to find such reliable service for my garage door issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-05-15",
        "review_rate": 5.0,
        "customer_name": "Larry Ross",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Amber D.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-12-13",
        "review_rate": 4.9,
        "customer_name": "Julia Marquez",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Veronica M.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-08-01",
        "review_rate": 5.0,
        "customer_name": "Sarah Williams",
        "service_type": "Garage Door Repair",
        "tech_name": "David H.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-07-07",
        "review_rate": 4.9,
        "customer_name": "Tammy Fox",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Pamela L.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-04-01",
        "review_rate": 5.0,
        "customer_name": "Angela Martin",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Jennifer V.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-03-16",
        "review_rate": 4.9,
        "customer_name": "Natalie Brewer",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Elizabeth C.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-09-22",
        "review_rate": 5.0,
        "customer_name": "Jennifer Elliott",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Charles H.",
        "review": "It was reassuring to see the technician\u2019s expertise in action. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-01-28",
        "review_rate": 4.9,
        "customer_name": "Vincent Lewis",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Lindsey B.",
        "review": "The level of professionalism displayed was remarkable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-04-08",
        "review_rate": 4.8,
        "customer_name": "Thomas Peterson",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Sharon C.",
        "review": "I appreciate how they kept me informed throughout the repair process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-09-18",
        "review_rate": 4.8,
        "customer_name": "Jason Dennis",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Clayton H.",
        "review": "This service was exactly what I needed for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-02-08",
        "review_rate": 4.8,
        "customer_name": "Benjamin Wilson",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Charles M.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-09-24",
        "review_rate": 4.8,
        "customer_name": "Gregory Johnson",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Vincent T.",
        "review": "The level of professionalism displayed was remarkable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-08-17",
        "review_rate": 4.8,
        "customer_name": "Mr. Paul Rivera IV",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Jeffrey S.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-08-28",
        "review_rate": 5.0,
        "customer_name": "Daniel Paul",
        "service_type": "Garage Door Services",
        "tech_name": "Michael B.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-03-21",
        "review_rate": 4.8,
        "customer_name": "Micheal Lane",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Richard J.",
        "review": "I can't express how satisfied I am with the garage door installation process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-07-15",
        "review_rate": 5.0,
        "customer_name": "Cheryl Norris",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Darlene J.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-06-10",
        "review_rate": 4.9,
        "customer_name": "Michael Reyes",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Andrew M.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-03-24",
        "review_rate": 4.9,
        "customer_name": "Tara Chavez",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Seth J.",
        "review": "The technician was patient and understanding during the process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-08-11",
        "review_rate": 4.9,
        "customer_name": "Vanessa Heath",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Victoria M.",
        "review": "The service exceeded my expectations in every way. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-09-23",
        "review_rate": 5.0,
        "customer_name": "Nichole Smith",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Jason R.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-08-21",
        "review_rate": 4.8,
        "customer_name": "Daniel Stevens",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Michael W.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-11-04",
        "review_rate": 4.9,
        "customer_name": "Matthew Fox",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Steve M.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-09-19",
        "review_rate": 5.0,
        "customer_name": "Mrs. Jessica Arnold",
        "service_type": "Garage Door Opener",
        "tech_name": "Frank O.",
        "review": "My garage door never worked better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-09-01",
        "review_rate": 4.8,
        "customer_name": "Darren Caldwell",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Ann R.",
        "review": "The crew was friendly and very efficient with the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-12-17",
        "review_rate": 4.9,
        "customer_name": "Angela Baker",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Jeff G.",
        "review": "The follow-up service was just as excellent as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-08-30",
        "review_rate": 4.9,
        "customer_name": "David Andersen",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Kyle C.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-08-02",
        "review_rate": 4.8,
        "customer_name": "Daniel Daniels",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Jacqueline P.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-05-13",
        "review_rate": 5.0,
        "customer_name": "Cristina Mitchell",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Kimberly P.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-05-11",
        "review_rate": 5.0,
        "customer_name": "Tammy Clay",
        "service_type": "Garage Door Track Repair",
        "tech_name": "John C.",
        "review": "The service was efficient and exceeded my expectations. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-08-13",
        "review_rate": 4.8,
        "customer_name": "Lisa Duncan",
        "service_type": "Garage Door Opener",
        "tech_name": "Kathleen S.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-10-13",
        "review_rate": 4.9,
        "customer_name": "Susan Bryant",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Kyle L.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-03-26",
        "review_rate": 4.8,
        "customer_name": "Adrian Miller",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Rachael Y.",
        "review": "Their attention to detail made all the difference in the outcome. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-09-04",
        "review_rate": 5.0,
        "customer_name": "Larry Diaz",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Dustin B.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-02-01",
        "review_rate": 4.8,
        "customer_name": "Kelli Martinez",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Zachary S.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-07-20",
        "review_rate": 4.9,
        "customer_name": "Ryan Haynes",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Anthony C.",
        "review": "I loved how the team worked seamlessly to get the job done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-02-07",
        "review_rate": 4.9,
        "customer_name": "Jesus Mann",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Cody J.",
        "review": "I felt valued as a customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-04-09",
        "review_rate": 4.9,
        "customer_name": "Brittany Price",
        "service_type": "Garage Door Repair",
        "tech_name": "Brandi H.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-05-15",
        "review_rate": 4.9,
        "customer_name": "Mary Boyer",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Ashley S.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-10-31",
        "review_rate": 5.0,
        "customer_name": "Mary Wood",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Maria S.",
        "review": "The technician was courteous and very knowledgeable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-07-31",
        "review_rate": 4.9,
        "customer_name": "Brittany Sherman",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Joseph C.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-03-29",
        "review_rate": 4.9,
        "customer_name": "Michael Payne",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Daniel S.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-03-03",
        "review_rate": 4.9,
        "customer_name": "Sarah Patel",
        "service_type": "Garage Door Maintenance",
        "tech_name": "William H.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-11-02",
        "review_rate": 5.0,
        "customer_name": "Bernard Kelley",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Melissa R.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-05-20",
        "review_rate": 4.9,
        "customer_name": "Amber Brown",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Kayla G.",
        "review": "The process was straightforward and hassle-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-06-16",
        "review_rate": 5.0,
        "customer_name": "Christine Williams",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Amy G.",
        "review": "Having my garage door repaired was a smooth experience thanks to the team. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-03-06",
        "review_rate": 4.9,
        "customer_name": "Laurie Kaiser",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Jasmine J.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-12-25",
        "review_rate": 4.8,
        "customer_name": "Christopher Turner",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Joy D.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-12-13",
        "review_rate": 4.8,
        "customer_name": "Joseph Hernandez",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Kendra C.",
        "review": "The service was efficient and exceeded my expectations. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-06-20",
        "review_rate": 4.9,
        "customer_name": "David Hernandez",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Jack J.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-09-16",
        "review_rate": 4.9,
        "customer_name": "Ms. Lisa Santos",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Jeremy R.",
        "review": "I was surprised by how fast the technician completed the work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-09-08",
        "review_rate": 4.8,
        "customer_name": "Dana Lindsey",
        "service_type": "Garage Door Off Track",
        "tech_name": "Brian M.",
        "review": "The service felt personal and catered to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-05-28",
        "review_rate": 4.8,
        "customer_name": "Melissa Dodson",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Mitchell M.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-09-28",
        "review_rate": 4.9,
        "customer_name": "Michele Jones",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Heidi P.",
        "review": "Their attention to detail made all the difference in the outcome. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-03-09",
        "review_rate": 4.8,
        "customer_name": "Christopher Wilcox",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Scott V.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-01-04",
        "review_rate": 4.8,
        "customer_name": "Lucas Chavez",
        "service_type": "Garage Door Repair",
        "tech_name": "Joseph J.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-08-26",
        "review_rate": 4.8,
        "customer_name": "Veronica Barnes",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Michelle H.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-07-08",
        "review_rate": 4.9,
        "customer_name": "Patrick Hamilton",
        "service_type": "Garage Door Insulation",
        "tech_name": "April W.",
        "review": "The whole process felt easy and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-12-18",
        "review_rate": 5.0,
        "customer_name": "Brian Duke",
        "service_type": "Garage Door Services",
        "tech_name": "Jennifer R.",
        "review": "The process was straightforward and hassle-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-01-27",
        "review_rate": 4.8,
        "customer_name": "Robert Johnson",
        "service_type": "Garage Door Off Track",
        "tech_name": "Robert W.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-05-11",
        "review_rate": 5.0,
        "customer_name": "Tyler Moon",
        "service_type": "Garage Door Installation",
        "tech_name": "Suzanne D.",
        "review": "The crew was friendly and very efficient with the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-11-23",
        "review_rate": 4.9,
        "customer_name": "Andrew Noble",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Shelby G.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-05-14",
        "review_rate": 4.9,
        "customer_name": "Eric Cline",
        "service_type": "Garage Door Installation",
        "tech_name": "Lindsay D.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-05-06",
        "review_rate": 4.8,
        "customer_name": "Matthew Smith",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Katie T.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-11-26",
        "review_rate": 4.9,
        "customer_name": "Tracy Cross",
        "service_type": "Garage Door Services",
        "tech_name": "Deborah S.",
        "review": "The technician's expertise was evident throughout the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-12-27",
        "review_rate": 5.0,
        "customer_name": "Traci Clark",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Christopher G.",
        "review": "I highly value the quick turnaround for my garage door repairs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-01-24",
        "review_rate": 4.8,
        "customer_name": "Natalie Stewart",
        "service_type": "Garage Door Off Track",
        "tech_name": "Brandon J.",
        "review": "I felt valued as a customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-02-08",
        "review_rate": 5.0,
        "customer_name": "Rose Smith DVM",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Sherri T.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-09-04",
        "review_rate": 4.8,
        "customer_name": "Sandra Craig",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Zachary M.",
        "review": "The technician's expertise was evident throughout the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-05-12",
        "review_rate": 4.8,
        "customer_name": "Jessica Keller",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Rebecca S.",
        "review": "I appreciated the thorough explanation of the work being done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-12-10",
        "review_rate": 5.0,
        "customer_name": "Laurie Richardson",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Michael W.",
        "review": "I was relieved to find such reliable service for my garage door issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-05-12",
        "review_rate": 4.9,
        "customer_name": "Thomas Lucas",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Jennifer C.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-07-25",
        "review_rate": 4.9,
        "customer_name": "Margaret Gonzales",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Charles B.",
        "review": "This company has set the standard for garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-02-18",
        "review_rate": 4.9,
        "customer_name": "Charles Palmer",
        "service_type": "Garage Door Opener",
        "tech_name": "Margaret C.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-08-24",
        "review_rate": 4.8,
        "customer_name": "Beth Davis",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Samuel C.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-04-13",
        "review_rate": 4.9,
        "customer_name": "Anita Shields",
        "service_type": "Garage Door Off Track",
        "tech_name": "Matthew P.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-02-27",
        "review_rate": 4.9,
        "customer_name": "Jennifer Price",
        "service_type": "Garage Door Track Repair",
        "tech_name": "William A.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-09-15",
        "review_rate": 4.9,
        "customer_name": "Paige Williams",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Robert D.",
        "review": "My garage door issue was resolved quickly and professionally. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-08-13",
        "review_rate": 5.0,
        "customer_name": "Lisa Hernandez",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Carl J.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-06-10",
        "review_rate": 4.8,
        "customer_name": "Wayne Wood",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Christy A.",
        "review": "I appreciated the thorough explanation of the work being done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-08-31",
        "review_rate": 4.9,
        "customer_name": "Olivia Crawford",
        "service_type": "Garage Door Repair",
        "tech_name": "Chris T.",
        "review": "I was blown away by how quickly the technician fixed my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-10-14",
        "review_rate": 4.9,
        "customer_name": "Kaylee Hall",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Joshua M.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-02-01",
        "review_rate": 4.9,
        "customer_name": "Matthew Hickman",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Laura M.",
        "review": "I would recommend them to anyone needing garage door services. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-01-25",
        "review_rate": 4.9,
        "customer_name": "Renee Smith",
        "service_type": "Garage Door Services",
        "tech_name": "Steven J.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-09-10",
        "review_rate": 5.0,
        "customer_name": "Kerri Orr",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Joshua M.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-09-03",
        "review_rate": 4.9,
        "customer_name": "Nicole Melton",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Elizabeth D.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-08-08",
        "review_rate": 4.8,
        "customer_name": "Luke Waller",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Robert B.",
        "review": "I felt reassured by their knowledge and experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-04-01",
        "review_rate": 4.8,
        "customer_name": "Jason Barker",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Melissa M.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-02-08",
        "review_rate": 4.9,
        "customer_name": "Wendy Clayton",
        "service_type": "Garage Door Repair",
        "tech_name": "Catherine G.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-09-21",
        "review_rate": 4.8,
        "customer_name": "Kathryn Dean",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Angela B.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-11-13",
        "review_rate": 5.0,
        "customer_name": "Jonathan Wright",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Dean D.",
        "review": "My experience with their garage door service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-10-13",
        "review_rate": 4.9,
        "customer_name": "Gary Brewer",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Albert R.",
        "review": "My garage door never worked better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-11-04",
        "review_rate": 4.9,
        "customer_name": "Michael Benitez",
        "service_type": "Garage Door Installation",
        "tech_name": "Christy P.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-11-06",
        "review_rate": 5.0,
        "customer_name": "Tammy Anderson",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Patricia H.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-10-17",
        "review_rate": 4.9,
        "customer_name": "Sonia Greer",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Holly T.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-11-01",
        "review_rate": 5.0,
        "customer_name": "Michael Mooney",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Kelly W.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-05-03",
        "review_rate": 4.9,
        "customer_name": "Jeffrey Morgan",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Cheryl M.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-07-19",
        "review_rate": 4.9,
        "customer_name": "Ann Thomas",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Ricky D.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-05-18",
        "review_rate": 5.0,
        "customer_name": "Mr. Charles Boone PhD",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Hannah M.",
        "review": "I found their approach to customer service refreshing and effective. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-07-10",
        "review_rate": 4.9,
        "customer_name": "Sarah Cruz",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Emily S.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-01-21",
        "review_rate": 5.0,
        "customer_name": "Amanda Murphy",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Raymond K.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-06-17",
        "review_rate": 4.8,
        "customer_name": "David Pacheco",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Scott S.",
        "review": "It was reassuring to see the technician\u2019s expertise in action. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-11-10",
        "review_rate": 4.9,
        "customer_name": "William Wall",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Brian R.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-08-03",
        "review_rate": 5.0,
        "customer_name": "Christopher Ferguson",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Andrew V.",
        "review": "I had an emergency with my garage door, and they responded quickly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-05-24",
        "review_rate": 5.0,
        "customer_name": "Amanda Robertson",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Anna E.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-08-11",
        "review_rate": 5.0,
        "customer_name": "Juan Ruiz",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Ronnie R.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-02-07",
        "review_rate": 4.9,
        "customer_name": "Lindsay Howell",
        "service_type": "Garage Door Off Track",
        "tech_name": "Nicole V.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-05-31",
        "review_rate": 4.9,
        "customer_name": "Gary Brown",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Elijah H.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-11-06",
        "review_rate": 4.8,
        "customer_name": "Shannon Alvarez",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Melanie F.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-02-28",
        "review_rate": 5.0,
        "customer_name": "Judith Adkins",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Cameron R.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-12-08",
        "review_rate": 4.8,
        "customer_name": "Mallory Little",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Jack C.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-04-05",
        "review_rate": 5.0,
        "customer_name": "Jamie Keller",
        "service_type": "Garage Door Repair",
        "tech_name": "Crystal J.",
        "review": "I would recommend them to anyone needing garage door services. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-11-25",
        "review_rate": 4.9,
        "customer_name": "Raymond Delgado",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Nicolas H.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-10-24",
        "review_rate": 4.9,
        "customer_name": "Natalie Thomas",
        "service_type": "Garage Door Opener",
        "tech_name": "Mariah A.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-08-17",
        "review_rate": 4.8,
        "customer_name": "John Johnson",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Paul V.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-11-10",
        "review_rate": 4.9,
        "customer_name": "John Knox",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Kathy L.",
        "review": "The service was efficient and exceeded my expectations. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-11-15",
        "review_rate": 5.0,
        "customer_name": "Carol Lewis",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Danielle W.",
        "review": "I couldn\u2019t be more satisfied with my garage door experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-10-07",
        "review_rate": 4.9,
        "customer_name": "Dawn Kennedy",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Mark L.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-03-16",
        "review_rate": 4.8,
        "customer_name": "Andrew Newton",
        "service_type": "Garage Door Maintenance",
        "tech_name": "John M.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-05-16",
        "review_rate": 4.8,
        "customer_name": "Stephen Scott",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Laurie C.",
        "review": "The technician made sure I understood all my options. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-09-26",
        "review_rate": 4.9,
        "customer_name": "Robin Brown",
        "service_type": "Garage Door Repair",
        "tech_name": "Kimberly T.",
        "review": "The service was efficient and exceeded my expectations. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-01-11",
        "review_rate": 4.8,
        "customer_name": "Adam Gonzalez",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Jonathan V.",
        "review": "I had an emergency with my garage door, and they responded quickly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-04-22",
        "review_rate": 4.9,
        "customer_name": "Kristin Harris",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Laura S.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-10-22",
        "review_rate": 5.0,
        "customer_name": "Kristen Walls",
        "service_type": "Garage Door Insulation",
        "tech_name": "Carolyn A.",
        "review": "My experience with their garage door service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-09-08",
        "review_rate": 4.8,
        "customer_name": "Sarah Roberts",
        "service_type": "Garage Door Installation",
        "tech_name": "Thomas T.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-06-04",
        "review_rate": 4.9,
        "customer_name": "Kathleen Taylor",
        "service_type": "Garage Door Off Track",
        "tech_name": "Clayton H.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-09-30",
        "review_rate": 5.0,
        "customer_name": "Stephen Brown",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Larry L.",
        "review": "I can't recommend this garage door service enough after my experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-05-25",
        "review_rate": 5.0,
        "customer_name": "Christopher Anderson",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Danielle R.",
        "review": "It was reassuring to see the technician\u2019s expertise in action. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-06-10",
        "review_rate": 4.9,
        "customer_name": "Melanie Hensley",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Robert B.",
        "review": "I was highly impressed with their professionalism throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-02-14",
        "review_rate": 5.0,
        "customer_name": "Nathan Harris",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Julie J.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-09-20",
        "review_rate": 5.0,
        "customer_name": "Jordan Wood",
        "service_type": "Garage Door Opener",
        "tech_name": "Karen J.",
        "review": "I was thoroughly impressed with their garage door safety inspection. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-09-10",
        "review_rate": 4.9,
        "customer_name": "Jordan Jones",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Jennifer A.",
        "review": "I felt valued as a customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-06-13",
        "review_rate": 4.8,
        "customer_name": "Allen Chapman",
        "service_type": "Garage Door Opener",
        "tech_name": "Eric S.",
        "review": "The technician was courteous and very knowledgeable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-10-20",
        "review_rate": 4.8,
        "customer_name": "Brenda Ramos",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Linda T.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-01-03",
        "review_rate": 4.9,
        "customer_name": "Nathan Schneider",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Kristen S.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-05-26",
        "review_rate": 4.8,
        "customer_name": "Jennifer Nelson",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Lisa R.",
        "review": "I was grateful for their quick response to my garage door emergency. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-09-02",
        "review_rate": 4.8,
        "customer_name": "Kathleen Sparks",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Robert M.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-09-01",
        "review_rate": 4.9,
        "customer_name": "Scott Howard",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Brian R.",
        "review": "The follow-up service was just as excellent as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-11-09",
        "review_rate": 4.9,
        "customer_name": "Jodi Cook",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Gregory T.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-09-30",
        "review_rate": 5.0,
        "customer_name": "Daniel Davis",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Rose H.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-07-03",
        "review_rate": 4.9,
        "customer_name": "Calvin Logan",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Angela K.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-11-14",
        "review_rate": 4.9,
        "customer_name": "Jessica Kelley",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Tracy S.",
        "review": "I was blown away by how quickly the technician fixed my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-12-26",
        "review_rate": 4.9,
        "customer_name": "Jessica Macdonald",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Jermaine M.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-03-01",
        "review_rate": 5.0,
        "customer_name": "Joshua Wilkerson",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Stephen K.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-11-20",
        "review_rate": 4.9,
        "customer_name": "Jamie Cox",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Allen Y.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-04-15",
        "review_rate": 4.9,
        "customer_name": "Thomas Bowen",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Nathan N.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-12-12",
        "review_rate": 4.9,
        "customer_name": "Russell Knight",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Dwayne R.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-04-06",
        "review_rate": 4.9,
        "customer_name": "Cameron Watts",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Linda W.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-11-05",
        "review_rate": 4.9,
        "customer_name": "Christina Hanna",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Brian M.",
        "review": "The technician's expertise was evident throughout the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-12-13",
        "review_rate": 4.8,
        "customer_name": "John Coleman",
        "service_type": "Garage Door Installation",
        "tech_name": "Erin S.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-10-14",
        "review_rate": 4.9,
        "customer_name": "Brian Moran",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Gary W.",
        "review": "I highly value the quick turnaround for my garage door repairs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-08-08",
        "review_rate": 4.9,
        "customer_name": "Tyler Mcdonald",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Brenda O.",
        "review": "I felt informed and confident about the repairs being made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-03-17",
        "review_rate": 4.9,
        "customer_name": "Tonya Schwartz",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Paul J.",
        "review": "I couldn\u2019t be more satisfied with my garage door experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-08-09",
        "review_rate": 4.8,
        "customer_name": "Donna Pittman",
        "service_type": "Garage Door Off Track",
        "tech_name": "John F.",
        "review": "The service was efficient and exceeded my expectations. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-02-18",
        "review_rate": 4.8,
        "customer_name": "John Sanchez",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Kayla C.",
        "review": "The technician was patient and understanding during the process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-07-22",
        "review_rate": 4.9,
        "customer_name": "Danielle Allen",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Adrienne C.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-12-21",
        "review_rate": 4.9,
        "customer_name": "Mr. Richard Cole",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Tyler H.",
        "review": "I felt reassured by their knowledge and experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-08-04",
        "review_rate": 5.0,
        "customer_name": "Tiffany Arroyo",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Ian F.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-02-24",
        "review_rate": 4.9,
        "customer_name": "Ralph Dorsey",
        "service_type": "Garage Door Opener",
        "tech_name": "Cassandra S.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-09-16",
        "review_rate": 4.8,
        "customer_name": "Dustin Johnson",
        "service_type": "Garage Door Insulation",
        "tech_name": "Zachary L.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-03-26",
        "review_rate": 4.8,
        "customer_name": "Brenda Wolfe",
        "service_type": "Garage Door Insulation",
        "tech_name": "Curtis C.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-08-31",
        "review_rate": 4.9,
        "customer_name": "Daniel Gonzalez",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Kristina G.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-08-27",
        "review_rate": 4.9,
        "customer_name": "Carlos Ruiz",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Justin R.",
        "review": "The crew was friendly and very efficient with the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-02-20",
        "review_rate": 5.0,
        "customer_name": "David Trujillo",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "April B.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-05-21",
        "review_rate": 4.9,
        "customer_name": "Elizabeth Huber",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Jessica Y.",
        "review": "I can't believe how much better my garage door operates now. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-05-28",
        "review_rate": 4.9,
        "customer_name": "Maria Thompson",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Robert P.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-06-21",
        "review_rate": 4.8,
        "customer_name": "Steven Briggs",
        "service_type": "Garage Door Opener",
        "tech_name": "Ellen W.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-03-12",
        "review_rate": 5.0,
        "customer_name": "Elaine Huerta",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "James F.",
        "review": "I can't believe how much better my garage door operates now. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-10-26",
        "review_rate": 4.8,
        "customer_name": "Kristine Graves",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Timothy R.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-06-01",
        "review_rate": 4.8,
        "customer_name": "Joshua Beck",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Phillip J.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-05-22",
        "review_rate": 4.9,
        "customer_name": "Lorraine Patterson",
        "service_type": "Garage Door Repair",
        "tech_name": "Ryan Y.",
        "review": "I felt reassured by their knowledge and experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-03-09",
        "review_rate": 5.0,
        "customer_name": "Dana Johnson",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Kyle M.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-05-12",
        "review_rate": 4.8,
        "customer_name": "Tonya Hammond",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "John C.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-04-15",
        "review_rate": 4.9,
        "customer_name": "Stephanie Marks",
        "service_type": "Garage Door Repair",
        "tech_name": "Jason G.",
        "review": "I was thoroughly impressed with their garage door safety inspection. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-12-01",
        "review_rate": 4.9,
        "customer_name": "Eric Olson",
        "service_type": "Garage Door Opener",
        "tech_name": "Stephen M.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-04-15",
        "review_rate": 4.9,
        "customer_name": "Rachael Haynes",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Carlos H.",
        "review": "The service exceeded my expectations in every way. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-10-28",
        "review_rate": 4.9,
        "customer_name": "Anthony Phillips",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Cathy C.",
        "review": "My experience with their garage door service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-06-04",
        "review_rate": 4.9,
        "customer_name": "Amanda Serrano",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Leah G.",
        "review": "I appreciated the straightforward communication during my service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-03-08",
        "review_rate": 5.0,
        "customer_name": "Jennifer Torres",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Gabrielle V.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-06-21",
        "review_rate": 4.9,
        "customer_name": "Haley Kim",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Amber G.",
        "review": "I was surprised by how fast the technician completed the work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-12-27",
        "review_rate": 4.8,
        "customer_name": "Kevin Garner",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Justin D.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-04-12",
        "review_rate": 4.8,
        "customer_name": "Brian Barrett",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Amanda H.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-04-03",
        "review_rate": 4.9,
        "customer_name": "Vanessa Riley",
        "service_type": "Garage Door Opener",
        "tech_name": "Lawrence M.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-05-21",
        "review_rate": 4.9,
        "customer_name": "Brian Holt",
        "service_type": "Garage Door Services",
        "tech_name": "Ashley C.",
        "review": "The process was straightforward and hassle-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-01-24",
        "review_rate": 5.0,
        "customer_name": "Gregory White",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Russell W.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-03-11",
        "review_rate": 4.9,
        "customer_name": "Mrs. Jennifer Bryant",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Tim W.",
        "review": "The technician was patient and understanding during the process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-05-02",
        "review_rate": 4.9,
        "customer_name": "Robert Stewart",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Kyle B.",
        "review": "I can't recommend this garage door service enough after my experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-07-12",
        "review_rate": 4.9,
        "customer_name": "Christopher Hall",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Gregory M.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-06-29",
        "review_rate": 4.9,
        "customer_name": "Patrick Perez",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Cindy F.",
        "review": "I appreciated the thorough explanation of the work being done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-01-29",
        "review_rate": 5.0,
        "customer_name": "Nathaniel Hernandez",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Jimmy S.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-08-23",
        "review_rate": 4.9,
        "customer_name": "Jonathan Hamilton",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Julia L.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-06-11",
        "review_rate": 5.0,
        "customer_name": "Wesley Espinoza",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Donald L.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-04-23",
        "review_rate": 4.8,
        "customer_name": "Ashley Martin",
        "service_type": "Garage Door Track Repair",
        "tech_name": "James C.",
        "review": "The level of professionalism displayed was remarkable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-12-14",
        "review_rate": 4.9,
        "customer_name": "Andrew Anderson",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Joseph N.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-08-17",
        "review_rate": 4.9,
        "customer_name": "James Schaefer",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Brandon O.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-11-15",
        "review_rate": 4.8,
        "customer_name": "Michael Stevens",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Charles J.",
        "review": "My garage door now functions flawlessly, thanks to their expertise. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-10-16",
        "review_rate": 5.0,
        "customer_name": "Richard Morris",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Heather M.",
        "review": "The service exceeded my expectations in every way. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-06-24",
        "review_rate": 4.9,
        "customer_name": "Donald Cummings",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Tanner R.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-01-04",
        "review_rate": 4.9,
        "customer_name": "Kevin Morgan",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Daniel C.",
        "review": "I was surprised by how fast the technician completed the work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-11-11",
        "review_rate": 4.8,
        "customer_name": "Julie Lopez",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Kevin P.",
        "review": "The service felt personal and catered to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-07-27",
        "review_rate": 4.8,
        "customer_name": "Amanda Anderson",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Trevor C.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-02-11",
        "review_rate": 4.9,
        "customer_name": "Ms. Jessica Moses",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "George S.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-05-03",
        "review_rate": 4.8,
        "customer_name": "Cameron Glenn",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Sara N.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-12-30",
        "review_rate": 5.0,
        "customer_name": "Timothy Gibbs",
        "service_type": "Garage Door Repair",
        "tech_name": "Kerri D.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-10-24",
        "review_rate": 4.9,
        "customer_name": "Wendy Miller",
        "service_type": "Garage Door Insulation",
        "tech_name": "Chloe W.",
        "review": "The crew was friendly and very efficient with the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-01-25",
        "review_rate": 4.9,
        "customer_name": "Jacob Mccoy",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "David J.",
        "review": "I was grateful for their quick response to my garage door emergency. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-04-23",
        "review_rate": 4.8,
        "customer_name": "Frank Brooks",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Jennifer C.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-09-13",
        "review_rate": 4.8,
        "customer_name": "Jennifer Juarez",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Christine P.",
        "review": "The crew was friendly and very efficient with the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-11-20",
        "review_rate": 5.0,
        "customer_name": "Ashley Roberts",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Bonnie A.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-01-28",
        "review_rate": 4.9,
        "customer_name": "Donald Watson",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Garrett R.",
        "review": "This service was exactly what I needed for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-05-14",
        "review_rate": 4.9,
        "customer_name": "John Rhodes",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Melissa L.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-08-28",
        "review_rate": 4.9,
        "customer_name": "Renee Craig DVM",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Steven P.",
        "review": "This company has set the standard for garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-08-23",
        "review_rate": 4.8,
        "customer_name": "Chad James",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Daniel G.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-05-21",
        "review_rate": 4.9,
        "customer_name": "Calvin Patel",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Christine R.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-06-27",
        "review_rate": 4.9,
        "customer_name": "Emily Mcclure",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Jeffrey C.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-08-24",
        "review_rate": 4.9,
        "customer_name": "Adam Ramirez",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Reginald R.",
        "review": "I was highly impressed with their professionalism throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-01-08",
        "review_rate": 4.8,
        "customer_name": "Daniel Gallagher",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Robert D.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-09-09",
        "review_rate": 4.9,
        "customer_name": "Mallory Martinez",
        "service_type": "Garage Door Off Track",
        "tech_name": "Robert W.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-11-10",
        "review_rate": 4.9,
        "customer_name": "Andrew Mills",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Alexander J.",
        "review": "Their attention to detail made all the difference in the outcome. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-05-31",
        "review_rate": 5.0,
        "customer_name": "Ryan Sanders",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Mitchell D.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-03-16",
        "review_rate": 5.0,
        "customer_name": "Paula Gould PhD",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Mark R.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-04-27",
        "review_rate": 5.0,
        "customer_name": "Belinda Green",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Kevin M.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-09-20",
        "review_rate": 4.9,
        "customer_name": "Brandon Kerr",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Jessica D.",
        "review": "I can't believe how much better my garage door operates now. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-02-20",
        "review_rate": 4.9,
        "customer_name": "James Price",
        "service_type": "Garage Door Installation",
        "tech_name": "Sara W.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-02-01",
        "review_rate": 4.9,
        "customer_name": "Debbie Edwards",
        "service_type": "Garage Door Installation",
        "tech_name": "Debra K.",
        "review": "I can't express how satisfied I am with the garage door installation process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-09-01",
        "review_rate": 4.8,
        "customer_name": "Clarence Lowe",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Brian Y.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-06-06",
        "review_rate": 4.8,
        "customer_name": "Donald Soto",
        "service_type": "Garage Door Opener",
        "tech_name": "Christian C.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-04-11",
        "review_rate": 4.8,
        "customer_name": "David Kemp",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Charles H.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-07-19",
        "review_rate": 4.8,
        "customer_name": "Ronnie Russell",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Brenda S.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-11-20",
        "review_rate": 4.8,
        "customer_name": "Joshua Wright",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Deborah R.",
        "review": "I felt informed and confident about the repairs being made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-10-18",
        "review_rate": 4.9,
        "customer_name": "Shawn Bradley",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Levi B.",
        "review": "I appreciate how they kept me informed throughout the repair process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-02-13",
        "review_rate": 4.9,
        "customer_name": "Roger Ashley",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Donna M.",
        "review": "I can't recommend this garage door service enough after my experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-05-07",
        "review_rate": 4.8,
        "customer_name": "Lucas Gomez",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Christopher D.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-05-01",
        "review_rate": 5.0,
        "customer_name": "Ernest May",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Chad G.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-06-06",
        "review_rate": 5.0,
        "customer_name": "Barbara Wright",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Samantha P.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-12-14",
        "review_rate": 4.9,
        "customer_name": "Kyle Thomas",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Jacqueline F.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-03-11",
        "review_rate": 5.0,
        "customer_name": "Christopher Harding",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Jesus F.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-12-22",
        "review_rate": 5.0,
        "customer_name": "Haley Lindsey",
        "service_type": "Garage Door Services",
        "tech_name": "Veronica D.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-06-24",
        "review_rate": 5.0,
        "customer_name": "Donald Berry",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Paul C.",
        "review": "I was grateful for their quick response to my garage door emergency. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-11-12",
        "review_rate": 4.9,
        "customer_name": "Shawn Green",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Angela C.",
        "review": "The service felt personal and catered to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-12-08",
        "review_rate": 5.0,
        "customer_name": "Nicole Wilson",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Christine H.",
        "review": "I appreciated the thorough explanation of the work being done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-03-26",
        "review_rate": 5.0,
        "customer_name": "Aaron Cortez",
        "service_type": "Garage Door Installation",
        "tech_name": "Kenneth R.",
        "review": "Having my garage door repaired was a smooth experience thanks to the team. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-01-19",
        "review_rate": 5.0,
        "customer_name": "Brittany Jones",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Amanda Y.",
        "review": "I was surprised by how fast the technician completed the work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-10-23",
        "review_rate": 4.8,
        "customer_name": "Kirk Wall",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Pamela B.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-06-16",
        "review_rate": 4.9,
        "customer_name": "Jenny Odonnell",
        "service_type": "Garage Door Off Track",
        "tech_name": "Desiree M.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-07-08",
        "review_rate": 4.9,
        "customer_name": "Thomas Olson",
        "service_type": "Garage Door Insulation",
        "tech_name": "Joanna R.",
        "review": "I felt valued as a customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-07-07",
        "review_rate": 4.9,
        "customer_name": "Mia Hernandez",
        "service_type": "Garage Door Services",
        "tech_name": "Jodi W.",
        "review": "I found their approach to customer service refreshing and effective. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-10-29",
        "review_rate": 4.9,
        "customer_name": "Casey Velasquez",
        "service_type": "Garage Door Opener",
        "tech_name": "Ronnie M.",
        "review": "I found their approach to customer service refreshing and effective. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-02-10",
        "review_rate": 4.9,
        "customer_name": "Emily Hansen",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Megan G.",
        "review": "I would recommend them to anyone needing garage door services. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-07-21",
        "review_rate": 4.9,
        "customer_name": "Blake Fernandez",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Barbara M.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-02-11",
        "review_rate": 4.8,
        "customer_name": "Kathryn Holland",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Tammy L.",
        "review": "I had an emergency with my garage door, and they responded quickly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-12-16",
        "review_rate": 4.9,
        "customer_name": "Katie English",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Brandon A.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-06-14",
        "review_rate": 4.9,
        "customer_name": "Tammy Massey",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Cindy W.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-05-25",
        "review_rate": 4.8,
        "customer_name": "Stephen Owens",
        "service_type": "Garage Door Opener",
        "tech_name": "Matthew N.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-01-30",
        "review_rate": 4.8,
        "customer_name": "Scott Johnson",
        "service_type": "Garage Door Opener",
        "tech_name": "Amanda R.",
        "review": "The quality of their work is evident in the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-06-12",
        "review_rate": 5.0,
        "customer_name": "Paul Stephens",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Karen L.",
        "review": "The follow-up service was just as excellent as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-07-07",
        "review_rate": 4.9,
        "customer_name": "Sara Wilson",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Daniel S.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-06-22",
        "review_rate": 4.9,
        "customer_name": "Gregory Barajas",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Nathaniel C.",
        "review": "The service exceeded my expectations in every way. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-02-17",
        "review_rate": 4.9,
        "customer_name": "Dale Flores",
        "service_type": "Garage Door Insulation",
        "tech_name": "Andrew W.",
        "review": "My garage door never worked better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-04-04",
        "review_rate": 4.9,
        "customer_name": "Kathryn Moore",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Heather P.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-12-23",
        "review_rate": 4.9,
        "customer_name": "Zachary Schroeder",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Anthony M.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-05-29",
        "review_rate": 4.8,
        "customer_name": "Robin Flores",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Joseph B.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-11-16",
        "review_rate": 4.8,
        "customer_name": "Alexandra Moore",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Adam F.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-07-07",
        "review_rate": 4.9,
        "customer_name": "William Stout",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Johnathan P.",
        "review": "The process was straightforward and hassle-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-07-21",
        "review_rate": 4.9,
        "customer_name": "Jonathan Campbell",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Emily C.",
        "review": "This company has set the standard for garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-11-02",
        "review_rate": 4.9,
        "customer_name": "Wanda Barr",
        "service_type": "Garage Door Insulation",
        "tech_name": "Hannah F.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-09-26",
        "review_rate": 4.8,
        "customer_name": "Taylor Brooks",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Brittany T.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-06-05",
        "review_rate": 4.9,
        "customer_name": "Gregory Wright",
        "service_type": "Garage Door Installation",
        "tech_name": "Heather H.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-10-12",
        "review_rate": 5.0,
        "customer_name": "Maria Turner",
        "service_type": "Garage Door Opener",
        "tech_name": "Matthew M.",
        "review": "Having my garage door repaired was a smooth experience thanks to the team. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-11-17",
        "review_rate": 4.9,
        "customer_name": "Sarah Floyd",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Dave B.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-08-11",
        "review_rate": 4.9,
        "customer_name": "Brenda Davis",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "John B.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-01-29",
        "review_rate": 4.8,
        "customer_name": "Paul Parker",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Anthony S.",
        "review": "I had an emergency with my garage door, and they responded quickly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-06-02",
        "review_rate": 5.0,
        "customer_name": "Judith Schwartz",
        "service_type": "Garage Door Repair",
        "tech_name": "Tabitha S.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-11-03",
        "review_rate": 4.8,
        "customer_name": "Jonathan Graham",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Mike R.",
        "review": "The technician was friendly and professional at all times. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-09-29",
        "review_rate": 4.8,
        "customer_name": "Erin Munoz",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "William H.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-12-23",
        "review_rate": 4.9,
        "customer_name": "David Lopez",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Anthony J.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-10-19",
        "review_rate": 4.8,
        "customer_name": "Robert Schroeder",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Michelle R.",
        "review": "The technician was friendly and professional at all times. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-07-07",
        "review_rate": 5.0,
        "customer_name": "Brian Andrews",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Samantha S.",
        "review": "I would recommend them to anyone needing garage door services. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-01-26",
        "review_rate": 4.8,
        "customer_name": "Michael Bailey",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Robin H.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-08-20",
        "review_rate": 4.8,
        "customer_name": "Amanda Norris",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Timothy W.",
        "review": "I was blown away by their level of expertise and service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-02-28",
        "review_rate": 5.0,
        "customer_name": "Jacob Nixon",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Dawn L.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-10-09",
        "review_rate": 4.9,
        "customer_name": "Shawn Turner",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Melissa O.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-04-03",
        "review_rate": 4.9,
        "customer_name": "Danielle Reid",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Philip P.",
        "review": "The technician's expertise was evident throughout the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-04-09",
        "review_rate": 4.9,
        "customer_name": "John Howe",
        "service_type": "Garage Door Opener",
        "tech_name": "Scott P.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-08-24",
        "review_rate": 5.0,
        "customer_name": "Dawn Meyers",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Rebecca J.",
        "review": "I was blown away by their level of expertise and service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-02-11",
        "review_rate": 4.8,
        "customer_name": "Mrs. Kathryn White",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Amy M.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-02-21",
        "review_rate": 4.9,
        "customer_name": "Adam Smith",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Stephen S.",
        "review": "I couldn\u2019t be more satisfied with my garage door experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-04-14",
        "review_rate": 4.9,
        "customer_name": "Eddie Warner",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Caroline B.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-08-02",
        "review_rate": 4.9,
        "customer_name": "Julie Flowers",
        "service_type": "Garage Door Installation",
        "tech_name": "Holly B.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-03-06",
        "review_rate": 4.9,
        "customer_name": "Cody Garner",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Tasha G.",
        "review": "I\u2019m very grateful for their outstanding customer support. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-11-18",
        "review_rate": 4.9,
        "customer_name": "Elizabeth Cruz",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "James N.",
        "review": "I was grateful for their quick response to my garage door emergency. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-10-04",
        "review_rate": 4.8,
        "customer_name": "Amber Morton",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Brandon S.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-10-31",
        "review_rate": 4.9,
        "customer_name": "Debbie Richardson",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Jeremy M.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-01-05",
        "review_rate": 4.9,
        "customer_name": "Sarah Perez",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Nicole R.",
        "review": "This company has set the standard for garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-07-19",
        "review_rate": 4.9,
        "customer_name": "Nicole Villa",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Jasmine C.",
        "review": "I appreciated the straightforward communication during my service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-12-06",
        "review_rate": 5.0,
        "customer_name": "Jennifer Malone",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Michael M.",
        "review": "I can't believe how much better my garage door operates now. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-08-06",
        "review_rate": 4.9,
        "customer_name": "Brian Burke",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Christopher R.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-03-29",
        "review_rate": 5.0,
        "customer_name": "Kathleen Mills",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Brian L.",
        "review": "Having my garage door repaired was a smooth experience thanks to the team. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-11-21",
        "review_rate": 4.9,
        "customer_name": "Jon Simpson",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Jose L.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-02-10",
        "review_rate": 4.9,
        "customer_name": "Bethany Wyatt",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Alexander C.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-03-29",
        "review_rate": 5.0,
        "customer_name": "Allison Dougherty",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Joel B.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-05-09",
        "review_rate": 4.9,
        "customer_name": "Jim Graham",
        "service_type": "Garage Door Repair",
        "tech_name": "Cassandra S.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-05-17",
        "review_rate": 4.9,
        "customer_name": "Tiffany Rivera",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Brianna W.",
        "review": "I appreciate how they kept me informed throughout the repair process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-07-15",
        "review_rate": 5.0,
        "customer_name": "Jose Brown",
        "service_type": "Garage Door Repair",
        "tech_name": "Teresa B.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-08-17",
        "review_rate": 4.9,
        "customer_name": "Nathan Flores",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Ruth M.",
        "review": "The technician made sure I understood all my options. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-05-24",
        "review_rate": 4.9,
        "customer_name": "Andrea Ross",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Brooke C.",
        "review": "I would rate their service as the best I've ever had for garage work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-12-22",
        "review_rate": 4.9,
        "customer_name": "Jonathan Santos",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Erin R.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-11-09",
        "review_rate": 4.9,
        "customer_name": "Jason Nelson",
        "service_type": "Garage Door Insulation",
        "tech_name": "Alyssa G.",
        "review": "I can't believe how much better my garage door operates now. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-05-11",
        "review_rate": 4.8,
        "customer_name": "Kevin Sandoval",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Virginia W.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-03-01",
        "review_rate": 4.9,
        "customer_name": "Bruce Landry",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "William B.",
        "review": "My experience with their garage door service was overwhelmingly positive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-09-05",
        "review_rate": 4.9,
        "customer_name": "Beth Thompson",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Larry H.",
        "review": "The technician was courteous and very knowledgeable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-05-13",
        "review_rate": 4.9,
        "customer_name": "Wendy Walker",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Brandon B.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-05-04",
        "review_rate": 5.0,
        "customer_name": "Ashley Hampton",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Dominic C.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-05-16",
        "review_rate": 4.8,
        "customer_name": "Angela James",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Crystal S.",
        "review": "I was surprised by how fast the technician completed the work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-07-31",
        "review_rate": 4.8,
        "customer_name": "Dakota Harvey",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Samantha B.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-04-28",
        "review_rate": 4.9,
        "customer_name": "Gail Ramos",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Lisa W.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-12-24",
        "review_rate": 5.0,
        "customer_name": "Angela Thompson",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Robert D.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-10-30",
        "review_rate": 4.9,
        "customer_name": "Danielle Choi",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Kathy C.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-10-13",
        "review_rate": 4.9,
        "customer_name": "Jason Kane",
        "service_type": "Garage Door Opener",
        "tech_name": "Amanda A.",
        "review": "The whole process felt easy and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-07-15",
        "review_rate": 4.9,
        "customer_name": "Manuel Jarvis",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Veronica B.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-05-13",
        "review_rate": 4.8,
        "customer_name": "Tiffany Simmons",
        "service_type": "Garage Door Opener",
        "tech_name": "Kristen C.",
        "review": "I appreciate how they kept me informed throughout the repair process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-01-30",
        "review_rate": 4.9,
        "customer_name": "Shawn Smith",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Michele W.",
        "review": "The whole process felt easy and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-10-26",
        "review_rate": 4.9,
        "customer_name": "Mary Peters",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Jeffrey O.",
        "review": "My garage door now functions flawlessly, thanks to their expertise. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-12-02",
        "review_rate": 4.9,
        "customer_name": "Christine Sullivan",
        "service_type": "Garage Door Repair",
        "tech_name": "Mary L.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-11-25",
        "review_rate": 5.0,
        "customer_name": "Lisa Lawson",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Julie R.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-02-17",
        "review_rate": 4.8,
        "customer_name": "Brady Thompson",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Pamela L.",
        "review": "The technician was courteous and very knowledgeable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-07-25",
        "review_rate": 4.8,
        "customer_name": "Vickie Harmon",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Kristi H.",
        "review": "I couldn\u2019t be more satisfied with my garage door experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-05-10",
        "review_rate": 4.9,
        "customer_name": "Joel Perez",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Richard A.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-01-10",
        "review_rate": 4.8,
        "customer_name": "Ashley Savage",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Caroline G.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-07-18",
        "review_rate": 4.8,
        "customer_name": "Caleb Woodard",
        "service_type": "Garage Door Insulation",
        "tech_name": "Mary B.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-04-04",
        "review_rate": 4.9,
        "customer_name": "Christopher Lopez",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Kimberly B.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-01-27",
        "review_rate": 5.0,
        "customer_name": "Jordan Cortez",
        "service_type": "Custom Garage Door Design",
        "tech_name": "David G.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-04-18",
        "review_rate": 5.0,
        "customer_name": "Christopher Goodman",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Susan G.",
        "review": "My experience with their garage door service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-03-27",
        "review_rate": 4.9,
        "customer_name": "Andrew Floyd",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Sarah S.",
        "review": "The process was straightforward and hassle-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-11-15",
        "review_rate": 4.9,
        "customer_name": "William Hill",
        "service_type": "Garage Door Insulation",
        "tech_name": "Brett M.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-01-28",
        "review_rate": 4.9,
        "customer_name": "Christina Jones",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Robert C.",
        "review": "The follow-up service was just as excellent as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-10-29",
        "review_rate": 4.9,
        "customer_name": "Steven Mccarty",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Jane S.",
        "review": "The technician was courteous and very knowledgeable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-05-16",
        "review_rate": 5.0,
        "customer_name": "Mr. Peter James",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Bradley A.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-03-15",
        "review_rate": 4.8,
        "customer_name": "Brandon Nelson",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Steven C.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-07-28",
        "review_rate": 4.9,
        "customer_name": "Elizabeth Williams",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "James M.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-12-29",
        "review_rate": 4.9,
        "customer_name": "Kimberly Smith",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Kristin F.",
        "review": "I felt reassured by their knowledge and experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-06-02",
        "review_rate": 4.8,
        "customer_name": "Michael Davidson",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Melissa T.",
        "review": "This company has set the standard for garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-06-28",
        "review_rate": 4.9,
        "customer_name": "David Wheeler",
        "service_type": "Garage Door Repair",
        "tech_name": "Jessica M.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-02-05",
        "review_rate": 4.9,
        "customer_name": "Matthew Chapman",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Charles H.",
        "review": "I felt reassured by their knowledge and experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-11-21",
        "review_rate": 4.9,
        "customer_name": "Craig King",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Dean S.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-06-21",
        "review_rate": 5.0,
        "customer_name": "Kelly Rhodes",
        "service_type": "Garage Door Repair",
        "tech_name": "Mallory W.",
        "review": "I felt valued as a customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-09-09",
        "review_rate": 4.9,
        "customer_name": "Donald Harris",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Carol G.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-10-08",
        "review_rate": 4.9,
        "customer_name": "Jose Strong",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Debbie J.",
        "review": "The technician made sure I understood all my options. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-08-26",
        "review_rate": 4.9,
        "customer_name": "Erik Church",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Melissa J.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-12-25",
        "review_rate": 4.9,
        "customer_name": "Kathryn Kelley",
        "service_type": "Garage Door Opener",
        "tech_name": "Ryan B.",
        "review": "I was surprised by how fast the technician completed the work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-09-23",
        "review_rate": 4.9,
        "customer_name": "Connie Jennings",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Christopher B.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-10-03",
        "review_rate": 4.9,
        "customer_name": "Julie Klein",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Michael H.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-05-25",
        "review_rate": 5.0,
        "customer_name": "Travis Mccarty",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Elizabeth H.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-10-24",
        "review_rate": 5.0,
        "customer_name": "Tara Reynolds",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "David C.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-02-18",
        "review_rate": 5.0,
        "customer_name": "Joseph Lane",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Christopher G.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-01-28",
        "review_rate": 4.8,
        "customer_name": "Amber Wilson",
        "service_type": "Garage Door Services",
        "tech_name": "Diane T.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-09-08",
        "review_rate": 4.8,
        "customer_name": "Donald Cortez",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Jason C.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-03-09",
        "review_rate": 5.0,
        "customer_name": "Joshua Hodges",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Amber T.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-04-24",
        "review_rate": 4.9,
        "customer_name": "Paul Nolan",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Lisa C.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-08-02",
        "review_rate": 5.0,
        "customer_name": "Jason Wolfe",
        "service_type": "Garage Door Off Track",
        "tech_name": "Melissa S.",
        "review": "The follow-up service was just as excellent as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-04-08",
        "review_rate": 4.9,
        "customer_name": "Zachary Luna",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Billy V.",
        "review": "The technician's expertise was evident throughout the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-07-27",
        "review_rate": 4.9,
        "customer_name": "Kristina Lutz DDS",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Melissa C.",
        "review": "The technician took great care in performing the job correctly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-08-14",
        "review_rate": 4.9,
        "customer_name": "Jeffrey Wilcox",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Mary N.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-09-22",
        "review_rate": 5.0,
        "customer_name": "Bianca Frederick",
        "service_type": "Garage Door Repair",
        "tech_name": "Mark W.",
        "review": "I was relieved to find such reliable service for my garage door issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-02-26",
        "review_rate": 4.8,
        "customer_name": "David Silva",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Kyle Z.",
        "review": "My garage door now functions flawlessly, thanks to their expertise. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-11-05",
        "review_rate": 4.9,
        "customer_name": "Patrick Roth",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Austin W.",
        "review": "The service exceeded my expectations in every way. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-04-07",
        "review_rate": 4.8,
        "customer_name": "Jane Barrett",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Debra B.",
        "review": "The crew was friendly and very efficient with the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-05-22",
        "review_rate": 4.9,
        "customer_name": "Dennis Parrish",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Martha B.",
        "review": "The technician took great care in performing the job correctly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-07-27",
        "review_rate": 4.9,
        "customer_name": "Leslie Jenkins",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Madison L.",
        "review": "I was surprised by how fast the technician completed the work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-04-11",
        "review_rate": 4.9,
        "customer_name": "Laura Goodwin",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Brian B.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-03-29",
        "review_rate": 4.8,
        "customer_name": "Amanda Ayers",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Vickie R.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-07-03",
        "review_rate": 4.8,
        "customer_name": "Ashley Martinez",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Aaron S.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-06-22",
        "review_rate": 5.0,
        "customer_name": "Kelly Hendrix",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Jose M.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-03-15",
        "review_rate": 4.8,
        "customer_name": "Douglas Bell",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Vanessa L.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-06-29",
        "review_rate": 4.9,
        "customer_name": "Elizabeth Hoover",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Barbara M.",
        "review": "I\u2019m very grateful for their outstanding customer support. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-05-31",
        "review_rate": 4.8,
        "customer_name": "Patricia Lane",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Jeremy Y.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-08-13",
        "review_rate": 4.8,
        "customer_name": "Michael Andrews",
        "service_type": "Garage Door Installation",
        "tech_name": "Richard M.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-04-01",
        "review_rate": 5.0,
        "customer_name": "Brenda Davis",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Katie H.",
        "review": "I was surprised by how fast the technician completed the work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-04-28",
        "review_rate": 4.9,
        "customer_name": "Jeremy Alvarado",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Sarah D.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-01-26",
        "review_rate": 4.8,
        "customer_name": "Rachel Simmons",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Anthony T.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-06-08",
        "review_rate": 4.8,
        "customer_name": "Amy Edwards",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Elizabeth K.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-01-03",
        "review_rate": 4.9,
        "customer_name": "Alexandria Arellano",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Amy L.",
        "review": "My experience with their garage door service was overwhelmingly positive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-12-25",
        "review_rate": 4.9,
        "customer_name": "Brendan Farmer",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Hannah B.",
        "review": "The service exceeded my expectations in every way. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-10-12",
        "review_rate": 5.0,
        "customer_name": "Angela Mckee",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Michael H.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-08-07",
        "review_rate": 5.0,
        "customer_name": "Jon Kane",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Daniel S.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-12-11",
        "review_rate": 4.9,
        "customer_name": "Keith Burns",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "George J.",
        "review": "I loved how the team worked seamlessly to get the job done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-02-17",
        "review_rate": 4.9,
        "customer_name": "Larry Gordon",
        "service_type": "Garage Door Repair",
        "tech_name": "Jacob O.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-08-21",
        "review_rate": 5.0,
        "customer_name": "Sydney Davis",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Garrett D.",
        "review": "I was highly impressed with their professionalism throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-05-17",
        "review_rate": 5.0,
        "customer_name": "Eric Cook",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Daniel P.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-10-20",
        "review_rate": 4.8,
        "customer_name": "Christopher Fowler",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Thomas R.",
        "review": "I loved how the team worked seamlessly to get the job done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-12-17",
        "review_rate": 5.0,
        "customer_name": "Don Mayo",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Madison W.",
        "review": "My experience with their garage door service was overwhelmingly positive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-07-26",
        "review_rate": 4.8,
        "customer_name": "Anthony Trevino",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Kathryn J.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-02-08",
        "review_rate": 4.8,
        "customer_name": "Gary Johnson",
        "service_type": "Garage Door Opener",
        "tech_name": "Ann C.",
        "review": "This service was exactly what I needed for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-02-19",
        "review_rate": 4.8,
        "customer_name": "Ethan Schmidt",
        "service_type": "Garage Door Installation",
        "tech_name": "Natalie D.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-08-16",
        "review_rate": 5.0,
        "customer_name": "Craig Fernandez",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Marissa L.",
        "review": "The follow-up service was just as excellent as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-07-04",
        "review_rate": 4.9,
        "customer_name": "Sarah Ortiz",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Andrew L.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-12-24",
        "review_rate": 4.8,
        "customer_name": "Ms. Kristin Odom",
        "service_type": "Garage Door Insulation",
        "tech_name": "Melissa G.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-12-12",
        "review_rate": 5.0,
        "customer_name": "Cynthia Young",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Shannon V.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-07-12",
        "review_rate": 4.9,
        "customer_name": "George Burton",
        "service_type": "Garage Door Insulation",
        "tech_name": "Jacqueline H.",
        "review": "I can't express how satisfied I am with the garage door installation process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-06-15",
        "review_rate": 4.9,
        "customer_name": "Brandi Hamilton",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Jose N.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-10-01",
        "review_rate": 4.8,
        "customer_name": "Patrick Stark",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Andrew H.",
        "review": "I felt valued as a customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-02-27",
        "review_rate": 4.8,
        "customer_name": "Catherine Rowe",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Karen W.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-06-10",
        "review_rate": 5.0,
        "customer_name": "Roger Adams",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Heidi B.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-10-01",
        "review_rate": 4.9,
        "customer_name": "Barbara Smith",
        "service_type": "Garage Door Installation",
        "tech_name": "Michael R.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-10-25",
        "review_rate": 4.8,
        "customer_name": "Sean Rios",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Lisa S.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-10-23",
        "review_rate": 4.9,
        "customer_name": "Gary Hawkins",
        "service_type": "Garage Door Insulation",
        "tech_name": "Joshua L.",
        "review": "The technician took great care in performing the job correctly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-10-04",
        "review_rate": 4.9,
        "customer_name": "Elizabeth Lawson",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Sharon S.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-02-06",
        "review_rate": 4.9,
        "customer_name": "Zachary Day",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Heidi C.",
        "review": "The technician's expertise was evident throughout the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-06-09",
        "review_rate": 4.8,
        "customer_name": "David Hall",
        "service_type": "Garage Door Opener",
        "tech_name": "Douglas V.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-05-28",
        "review_rate": 4.9,
        "customer_name": "James Juarez",
        "service_type": "Garage Door Installation",
        "tech_name": "Sarah R.",
        "review": "I would recommend them to anyone needing garage door services. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-01-26",
        "review_rate": 4.9,
        "customer_name": "Randy Tucker",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Lori N.",
        "review": "The quality of their work is evident in the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-02-17",
        "review_rate": 4.8,
        "customer_name": "Ashley Grant",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Javier B.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-03-11",
        "review_rate": 5.0,
        "customer_name": "Jamie Smith",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Vincent B.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-02-06",
        "review_rate": 5.0,
        "customer_name": "Michael Williams",
        "service_type": "Garage Door Installation",
        "tech_name": "Lori R.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-12-08",
        "review_rate": 4.9,
        "customer_name": "Debra Lewis",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Kathleen R.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-01-17",
        "review_rate": 4.9,
        "customer_name": "Todd Holmes",
        "service_type": "Garage Door Installation",
        "tech_name": "Sharon B.",
        "review": "It was reassuring to see the technician\u2019s expertise in action. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-05-10",
        "review_rate": 4.9,
        "customer_name": "Christopher Jones",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Richard T.",
        "review": "I would rate their service as the best I've ever had for garage work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-03-07",
        "review_rate": 5.0,
        "customer_name": "Jeffery Nichols",
        "service_type": "Garage Door Repair",
        "tech_name": "Jamie H.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-07-20",
        "review_rate": 4.9,
        "customer_name": "Zachary Banks",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Samantha D.",
        "review": "I appreciate how they kept me informed throughout the repair process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-07-08",
        "review_rate": 4.9,
        "customer_name": "William Blackburn",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Nicholas P.",
        "review": "I appreciate how they kept me informed throughout the repair process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-09-21",
        "review_rate": 4.9,
        "customer_name": "Sarah Moran",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Christopher A.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-08-02",
        "review_rate": 4.8,
        "customer_name": "Amanda Palmer",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Micheal A.",
        "review": "This service was exactly what I needed for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-02-02",
        "review_rate": 4.8,
        "customer_name": "Sarah Cox",
        "service_type": "Garage Door Services",
        "tech_name": "Jessica M.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-03-01",
        "review_rate": 4.9,
        "customer_name": "Whitney Reyes",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Kiara S.",
        "review": "I felt informed and confident about the repairs being made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-01-19",
        "review_rate": 4.8,
        "customer_name": "Deanna Peterson",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Paul W.",
        "review": "I appreciated the thorough explanation of the work being done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-05-23",
        "review_rate": 4.9,
        "customer_name": "Sarah Young",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Lori J.",
        "review": "Having my garage door repaired was a smooth experience thanks to the team. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-06-08",
        "review_rate": 5.0,
        "customer_name": "Albert Burnett",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Shawn G.",
        "review": "The process was straightforward and hassle-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-11-07",
        "review_rate": 5.0,
        "customer_name": "Kristin Jones",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Laura V.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-11-07",
        "review_rate": 4.9,
        "customer_name": "Angela Barr",
        "service_type": "Garage Door Services",
        "tech_name": "Gerald M.",
        "review": "The quality of their work is evident in the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-12-31",
        "review_rate": 4.9,
        "customer_name": "Kim Cole",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Laura M.",
        "review": "This company has set the standard for garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-03-09",
        "review_rate": 4.9,
        "customer_name": "David Murphy",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Angela C.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-09-17",
        "review_rate": 4.9,
        "customer_name": "Deborah Hobbs",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Jon B.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-09-25",
        "review_rate": 4.9,
        "customer_name": "Mark Williams",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Abigail M.",
        "review": "The service was efficient and exceeded my expectations. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-12-19",
        "review_rate": 4.9,
        "customer_name": "Jesse Riley",
        "service_type": "Garage Door Insulation",
        "tech_name": "Gary M.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-04-17",
        "review_rate": 4.9,
        "customer_name": "Jeffrey Ramirez",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Breanna H.",
        "review": "I was thoroughly impressed with their garage door safety inspection. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-08-07",
        "review_rate": 4.8,
        "customer_name": "Melissa Shaffer",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Samantha D.",
        "review": "The level of professionalism displayed was remarkable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-03-05",
        "review_rate": 4.9,
        "customer_name": "Sandra Young",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Madeline R.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-03-31",
        "review_rate": 4.9,
        "customer_name": "Brooke Andrews",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Cameron H.",
        "review": "It was reassuring to see the technician\u2019s expertise in action. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-07-13",
        "review_rate": 4.8,
        "customer_name": "Jessica Jordan",
        "service_type": "Garage Door Off Track",
        "tech_name": "Chelsea W.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-08-21",
        "review_rate": 4.9,
        "customer_name": "Haley Perez",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Richard D.",
        "review": "I was highly impressed with their professionalism throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-03-05",
        "review_rate": 4.9,
        "customer_name": "Elizabeth Stephens",
        "service_type": "Garage Door Repair",
        "tech_name": "Andrew P.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-08-21",
        "review_rate": 4.9,
        "customer_name": "Christine Mitchell",
        "service_type": "Garage Door Installation",
        "tech_name": "Paula M.",
        "review": "The technician was friendly and professional at all times. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-12-24",
        "review_rate": 4.9,
        "customer_name": "Mrs. Yvette Smith",
        "service_type": "Garage Door Installation",
        "tech_name": "Ryan H.",
        "review": "I was grateful for their quick response to my garage door emergency. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-11-07",
        "review_rate": 4.9,
        "customer_name": "Taylor Thompson",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Jennifer O.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-10-31",
        "review_rate": 5.0,
        "customer_name": "Claudia Kennedy",
        "service_type": "Garage Door Insulation",
        "tech_name": "James C.",
        "review": "The level of professionalism displayed was remarkable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-08-07",
        "review_rate": 4.9,
        "customer_name": "Diana Edwards",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "David R.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-10-28",
        "review_rate": 4.8,
        "customer_name": "Haley Moore",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Scott C.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-09-27",
        "review_rate": 5.0,
        "customer_name": "Andrea Robinson",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Zachary J.",
        "review": "The level of professionalism displayed was remarkable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-03-29",
        "review_rate": 4.9,
        "customer_name": "Caleb Adkins",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Tammy L.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-01-31",
        "review_rate": 4.9,
        "customer_name": "Zachary Bowen",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Victoria B.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-03-06",
        "review_rate": 5.0,
        "customer_name": "Hannah Osborne",
        "service_type": "Garage Door Insulation",
        "tech_name": "Diane G.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-12-17",
        "review_rate": 4.9,
        "customer_name": "Matthew Krueger",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Stacy B.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-01-18",
        "review_rate": 4.9,
        "customer_name": "Heather Gordon",
        "service_type": "Garage Door Services",
        "tech_name": "Mary V.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-08-02",
        "review_rate": 4.9,
        "customer_name": "Christopher Shepherd",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Julia W.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-01-14",
        "review_rate": 4.9,
        "customer_name": "Jacob Choi",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Jennifer H.",
        "review": "The service was efficient and exceeded my expectations. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-11-03",
        "review_rate": 4.9,
        "customer_name": "Keith Mitchell",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Walter H.",
        "review": "This company has set the standard for garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-01-24",
        "review_rate": 5.0,
        "customer_name": "Ricardo Glover",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Katherine F.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-12-27",
        "review_rate": 4.8,
        "customer_name": "Stephanie Wright",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Brittney L.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-07-16",
        "review_rate": 4.8,
        "customer_name": "Carlos Hunter",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Haley L.",
        "review": "The whole process felt easy and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-09-25",
        "review_rate": 4.8,
        "customer_name": "Todd Downs",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Tina J.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-10-12",
        "review_rate": 4.9,
        "customer_name": "Ann Jackson",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Mary L.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-12-26",
        "review_rate": 4.9,
        "customer_name": "Devin Luna",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Christie H.",
        "review": "I can't believe how much better my garage door operates now. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-04-14",
        "review_rate": 4.8,
        "customer_name": "David Rivers",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Brendan P.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-05-20",
        "review_rate": 4.9,
        "customer_name": "Autumn Castillo",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Mariah E.",
        "review": "My garage door issue was resolved quickly and professionally. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-02-07",
        "review_rate": 5.0,
        "customer_name": "Morgan White",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Rita M.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-12-09",
        "review_rate": 4.8,
        "customer_name": "Mark Hopkins",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Valerie B.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-06-07",
        "review_rate": 4.8,
        "customer_name": "Christine Peters",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Rose G.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-11-08",
        "review_rate": 4.9,
        "customer_name": "Jennifer Jenkins",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Angela H.",
        "review": "My garage door issue was resolved quickly and professionally. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-09-28",
        "review_rate": 4.9,
        "customer_name": "Christopher Nolan",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Emily I.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-11-07",
        "review_rate": 4.9,
        "customer_name": "Angie Jordan",
        "service_type": "Garage Door Maintenance",
        "tech_name": "John F.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-07-23",
        "review_rate": 5.0,
        "customer_name": "Michael Mckee",
        "service_type": "Garage Door Repair",
        "tech_name": "Alison J.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-02-05",
        "review_rate": 4.9,
        "customer_name": "Andrew Ballard",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Tonya C.",
        "review": "I was blown away by their level of expertise and service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-07-17",
        "review_rate": 4.8,
        "customer_name": "Jill Morales",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Nancy H.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-05-07",
        "review_rate": 4.9,
        "customer_name": "Darryl Hull",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Roy M.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-05-23",
        "review_rate": 4.8,
        "customer_name": "Albert Williams",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Thomas S.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-04-09",
        "review_rate": 4.9,
        "customer_name": "Adam Williams",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Matthew A.",
        "review": "I loved how the team worked seamlessly to get the job done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-09-25",
        "review_rate": 4.9,
        "customer_name": "Derek Barnes DDS",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Amanda N.",
        "review": "The technician was courteous and very knowledgeable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-10-21",
        "review_rate": 4.9,
        "customer_name": "Jacob Koch",
        "service_type": "Garage Door Installation",
        "tech_name": "Juan L.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-04-12",
        "review_rate": 4.9,
        "customer_name": "Samuel Caldwell",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Gloria B.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-03-23",
        "review_rate": 4.8,
        "customer_name": "Nathaniel Grant",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Dan L.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-02-13",
        "review_rate": 4.9,
        "customer_name": "Rodney Ford",
        "service_type": "Garage Door Track Repair",
        "tech_name": "David G.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-06-14",
        "review_rate": 4.8,
        "customer_name": "Kathy Chang MD",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Michael B.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-05-13",
        "review_rate": 4.9,
        "customer_name": "John Powell",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Jon R.",
        "review": "I would recommend them to anyone needing garage door services. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-08-16",
        "review_rate": 5.0,
        "customer_name": "Tammy Rosario",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Michelle W.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-03-11",
        "review_rate": 4.8,
        "customer_name": "Isaac Bauer",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Melissa J.",
        "review": "I could tell they genuinely cared about their customers. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-08-17",
        "review_rate": 4.8,
        "customer_name": "Bradley Olsen",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Mitchell H.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-06-26",
        "review_rate": 4.9,
        "customer_name": "Stacey Andrade",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Gary M.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-07-09",
        "review_rate": 4.8,
        "customer_name": "Melissa Jones",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Connie P.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-06-16",
        "review_rate": 4.9,
        "customer_name": "Willie Lopez Jr.",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Sean D.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-12-22",
        "review_rate": 4.9,
        "customer_name": "John Rogers",
        "service_type": "Garage Door Off Track",
        "tech_name": "Timothy S.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-05-16",
        "review_rate": 4.9,
        "customer_name": "Joshua Hebert",
        "service_type": "Garage Door Installation",
        "tech_name": "Kyle B.",
        "review": "I highly value the quick turnaround for my garage door repairs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-10-15",
        "review_rate": 4.8,
        "customer_name": "Tanya Stevens",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Danny L.",
        "review": "I found their approach to customer service refreshing and effective. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-04-16",
        "review_rate": 4.9,
        "customer_name": "Ashley Smith",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Roy H.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-12-09",
        "review_rate": 4.9,
        "customer_name": "Christine Mendez",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Lauren T.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-07-04",
        "review_rate": 4.9,
        "customer_name": "Sarah Smith",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Shawn C.",
        "review": "The service was efficient and exceeded my expectations. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-01-03",
        "review_rate": 4.8,
        "customer_name": "Brian Hensley",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Thomas L.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-06-05",
        "review_rate": 4.9,
        "customer_name": "Tiffany Mathis",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Samantha C.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-01-18",
        "review_rate": 4.9,
        "customer_name": "Julie Patton",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Cindy T.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-07-01",
        "review_rate": 5.0,
        "customer_name": "Daniel Smith",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Brianna F.",
        "review": "Their attention to detail made all the difference in the outcome. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-05-06",
        "review_rate": 5.0,
        "customer_name": "Connor Spears",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Ellen S.",
        "review": "The service was efficient and exceeded my expectations. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-03-04",
        "review_rate": 5.0,
        "customer_name": "Amber Brown",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Gary S.",
        "review": "My experience with their garage door service was overwhelmingly positive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-02-06",
        "review_rate": 4.9,
        "customer_name": "Kristin Bell",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Kimberly P.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-08-21",
        "review_rate": 5.0,
        "customer_name": "Michelle Fritz",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Anthony M.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-07-15",
        "review_rate": 4.8,
        "customer_name": "Justin Marshall",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Kelly L.",
        "review": "The technician's expertise was evident throughout the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-08-15",
        "review_rate": 4.8,
        "customer_name": "Roger Hawkins",
        "service_type": "Garage Door Off Track",
        "tech_name": "Jesus V.",
        "review": "My garage door now functions flawlessly, thanks to their expertise. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-10-01",
        "review_rate": 4.9,
        "customer_name": "Kimberly Rivera",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "William M.",
        "review": "I appreciated the thorough explanation of the work being done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-05-08",
        "review_rate": 4.9,
        "customer_name": "Krista Wilson",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Nicole W.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-04-15",
        "review_rate": 4.9,
        "customer_name": "Keith Thomas",
        "service_type": "Garage Door Installation",
        "tech_name": "Brian P.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-08-07",
        "review_rate": 5.0,
        "customer_name": "Rachel Meyers DVM",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Cristian S.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-08-17",
        "review_rate": 5.0,
        "customer_name": "Destiny Smith",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Sarah M.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-01-15",
        "review_rate": 4.9,
        "customer_name": "Mark Ayala",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Xavier L.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-04-30",
        "review_rate": 5.0,
        "customer_name": "Lori Warren",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Cameron R.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-06-11",
        "review_rate": 4.8,
        "customer_name": "Jeremy Simmons",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Kevin M.",
        "review": "I appreciate how they kept me informed throughout the repair process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-09-11",
        "review_rate": 4.9,
        "customer_name": "Daniel Campbell",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Lisa P.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-10-13",
        "review_rate": 4.9,
        "customer_name": "Stacy Green",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Monica J.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-08-15",
        "review_rate": 4.9,
        "customer_name": "Carl Evans",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Jose B.",
        "review": "The quality of their work is evident in the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-05-12",
        "review_rate": 4.9,
        "customer_name": "Brian Cooper",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Jillian L.",
        "review": "This service was exactly what I needed for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-04-24",
        "review_rate": 4.9,
        "customer_name": "Dylan Frost",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Ryan B.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-02-25",
        "review_rate": 4.9,
        "customer_name": "Michelle Larson",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Marissa P.",
        "review": "The service was efficient and exceeded my expectations. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-03-26",
        "review_rate": 4.8,
        "customer_name": "Connie Lawrence",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Joshua R.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-08-01",
        "review_rate": 4.8,
        "customer_name": "Amy Goodman",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Samantha D.",
        "review": "I can't believe how much better my garage door operates now. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-08-22",
        "review_rate": 5.0,
        "customer_name": "Kevin Foster",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Mercedes M.",
        "review": "I was grateful for their quick response to my garage door emergency. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-06-29",
        "review_rate": 4.9,
        "customer_name": "Karen Henry",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Julie S.",
        "review": "My garage door now functions flawlessly, thanks to their expertise. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-08-22",
        "review_rate": 4.8,
        "customer_name": "Victoria Clark",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Nicholas M.",
        "review": "I\u2019m very grateful for their outstanding customer support. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-08-07",
        "review_rate": 4.8,
        "customer_name": "Roy Miller",
        "service_type": "Garage Door Repair",
        "tech_name": "Ronald M.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-01-25",
        "review_rate": 5.0,
        "customer_name": "Julie Smith",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Andrew W.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-12-07",
        "review_rate": 5.0,
        "customer_name": "Manuel Hunter",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Chad S.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-03-12",
        "review_rate": 4.9,
        "customer_name": "Christopher Hensley",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Jacob N.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-12-10",
        "review_rate": 4.9,
        "customer_name": "Joseph Sheppard",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Jonathan R.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-04-21",
        "review_rate": 4.8,
        "customer_name": "Manuel Hayes",
        "service_type": "Garage Door Services",
        "tech_name": "Samuel B.",
        "review": "It was reassuring to see the technician\u2019s expertise in action. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-04-12",
        "review_rate": 5.0,
        "customer_name": "Robert Vasquez",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Andrew H.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-09-22",
        "review_rate": 5.0,
        "customer_name": "Laura Moore",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Mark H.",
        "review": "The technician's expertise was evident throughout the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-01-10",
        "review_rate": 4.8,
        "customer_name": "Sierra Simpson",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Tricia A.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-09-23",
        "review_rate": 4.9,
        "customer_name": "Robert Levine",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Morgan G.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-10-30",
        "review_rate": 4.9,
        "customer_name": "Thomas Ortiz",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Michele S.",
        "review": "It was reassuring to see the technician\u2019s expertise in action. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-11-02",
        "review_rate": 4.8,
        "customer_name": "Miguel Robinson",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Kirsten M.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-07-15",
        "review_rate": 4.8,
        "customer_name": "Sheila Howell",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Barbara H.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-04-21",
        "review_rate": 4.8,
        "customer_name": "Jamie Bray",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Richard R.",
        "review": "The follow-up service was just as excellent as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-04-23",
        "review_rate": 5.0,
        "customer_name": "Julia Reid",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Laura B.",
        "review": "I would rate their service as the best I've ever had for garage work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-02-25",
        "review_rate": 4.9,
        "customer_name": "James Dillon",
        "service_type": "Garage Door Opener",
        "tech_name": "Lisa S.",
        "review": "I can't express how satisfied I am with the garage door installation process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-05-04",
        "review_rate": 4.9,
        "customer_name": "Angela Wilkinson",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Aaron W.",
        "review": "I was blown away by their level of expertise and service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-06-11",
        "review_rate": 5.0,
        "customer_name": "Nicole Gomez",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Edward H.",
        "review": "I highly value the quick turnaround for my garage door repairs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-07-03",
        "review_rate": 5.0,
        "customer_name": "Jason Munoz",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Christine K.",
        "review": "I was blown away by their level of expertise and service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-09-15",
        "review_rate": 4.9,
        "customer_name": "Frank Archer",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "John B.",
        "review": "I would recommend them to anyone needing garage door services. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-08-04",
        "review_rate": 4.9,
        "customer_name": "Angelica Harvey",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Tina M.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-09-01",
        "review_rate": 4.9,
        "customer_name": "Roger Gibson",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Tiffany W.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-12-02",
        "review_rate": 5.0,
        "customer_name": "Robert Salinas",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Selena F.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-03-31",
        "review_rate": 4.9,
        "customer_name": "Kelly Stevenson",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Cynthia M.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-02-17",
        "review_rate": 4.8,
        "customer_name": "Vincent Cooper",
        "service_type": "Garage Door Services",
        "tech_name": "Jacob M.",
        "review": "My garage door now functions flawlessly, thanks to their expertise. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-03-13",
        "review_rate": 4.9,
        "customer_name": "Edwin Wright",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Jared K.",
        "review": "I had an emergency with my garage door, and they responded quickly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-07-08",
        "review_rate": 5.0,
        "customer_name": "Patricia Byrd",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Sean R.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-03-28",
        "review_rate": 4.9,
        "customer_name": "Sarah Taylor",
        "service_type": "Garage Door Services",
        "tech_name": "Rachel M.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-08-02",
        "review_rate": 4.8,
        "customer_name": "Brandon Johnson",
        "service_type": "Garage Door Off Track",
        "tech_name": "Richard C.",
        "review": "The process was straightforward and hassle-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-10-11",
        "review_rate": 4.9,
        "customer_name": "Connie Nelson",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Matthew M.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-06-08",
        "review_rate": 4.8,
        "customer_name": "Madison Smith",
        "service_type": "Garage Door Off Track",
        "tech_name": "Julie N.",
        "review": "I can't recommend this garage door service enough after my experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-08-12",
        "review_rate": 4.9,
        "customer_name": "Andrea Gutierrez",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Jon H.",
        "review": "The technician was courteous and very knowledgeable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-04-05",
        "review_rate": 4.8,
        "customer_name": "Mark Mathis",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Carl M.",
        "review": "I felt valued as a customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-02-13",
        "review_rate": 4.8,
        "customer_name": "Thomas Villarreal",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Donna S.",
        "review": "I appreciated the straightforward communication during my service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-03-17",
        "review_rate": 4.9,
        "customer_name": "Erica Alexander",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Nancy F.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-09-23",
        "review_rate": 4.9,
        "customer_name": "Tammy Reid",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Leah M.",
        "review": "The level of professionalism displayed was remarkable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-03-21",
        "review_rate": 5.0,
        "customer_name": "Jessica Frederick",
        "service_type": "Garage Door Off Track",
        "tech_name": "Daniel S.",
        "review": "They handled my garage door issues with expertise and care. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-09-27",
        "review_rate": 4.9,
        "customer_name": "Michael Nelson",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Michael M.",
        "review": "I highly value the quick turnaround for my garage door repairs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-04-29",
        "review_rate": 5.0,
        "customer_name": "Antonio Smith",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Connie A.",
        "review": "Having my garage door repaired was a smooth experience thanks to the team. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-08-07",
        "review_rate": 4.8,
        "customer_name": "Vanessa Salinas",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Timothy B.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-03-08",
        "review_rate": 4.9,
        "customer_name": "Sharon Mason",
        "service_type": "Garage Door Opener",
        "tech_name": "Ryan L.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-06-12",
        "review_rate": 4.9,
        "customer_name": "Mark Taylor",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Amy S.",
        "review": "I could tell they genuinely cared about their customers. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-03-20",
        "review_rate": 4.9,
        "customer_name": "Lisa Parker",
        "service_type": "Garage Door Insulation",
        "tech_name": "Courtney B.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-09-09",
        "review_rate": 4.8,
        "customer_name": "Mr. Matthew Smith",
        "service_type": "Garage Door Insulation",
        "tech_name": "Tammy M.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-05-11",
        "review_rate": 5.0,
        "customer_name": "Marissa Yu",
        "service_type": "Garage Door Insulation",
        "tech_name": "Megan F.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-08-26",
        "review_rate": 4.9,
        "customer_name": "David Ramsey",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Joseph S.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-11-17",
        "review_rate": 4.9,
        "customer_name": "Melvin Martinez",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Jason M.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-04-06",
        "review_rate": 4.9,
        "customer_name": "Sandra Cummings",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Martha S.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-05-19",
        "review_rate": 4.8,
        "customer_name": "Michelle Hutchinson",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Jennifer D.",
        "review": "My experience with their garage door service was overwhelmingly positive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-09-09",
        "review_rate": 4.9,
        "customer_name": "Melissa Michael",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Jeffrey T.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-02-23",
        "review_rate": 5.0,
        "customer_name": "Stephanie Trevino",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Sarah W.",
        "review": "I can't recommend this garage door service enough after my experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-11-06",
        "review_rate": 4.8,
        "customer_name": "Christopher Zimmerman",
        "service_type": "Garage Door Repair",
        "tech_name": "Sarah K.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-12-25",
        "review_rate": 4.8,
        "customer_name": "Sara Hodges",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Christopher Z.",
        "review": "I was relieved to find such reliable service for my garage door issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-02-09",
        "review_rate": 4.8,
        "customer_name": "John Barber",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Jody R.",
        "review": "I appreciated the thorough explanation of the work being done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-03-07",
        "review_rate": 4.9,
        "customer_name": "David Lopez",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Peter G.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-06-12",
        "review_rate": 4.9,
        "customer_name": "Sandra Allen",
        "service_type": "Garage Door Off Track",
        "tech_name": "Corey O.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-05-06",
        "review_rate": 4.9,
        "customer_name": "Pamela Edwards",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Joshua S.",
        "review": "The service was efficient and exceeded my expectations. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-06-09",
        "review_rate": 4.8,
        "customer_name": "Katherine Grant",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "William R.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-12-14",
        "review_rate": 5.0,
        "customer_name": "Blake Anderson",
        "service_type": "Garage Door Opener",
        "tech_name": "Richard M.",
        "review": "I could tell they genuinely cared about their customers. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-02-09",
        "review_rate": 4.8,
        "customer_name": "Jeremy Cooper",
        "service_type": "Garage Door Opener",
        "tech_name": "Clifford H.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-09-19",
        "review_rate": 5.0,
        "customer_name": "Thomas Christensen",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Lisa M.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-02-04",
        "review_rate": 4.9,
        "customer_name": "Laura Roberts",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Christopher H.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-07-05",
        "review_rate": 4.9,
        "customer_name": "Bryan Harrison",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Richard R.",
        "review": "The service was efficient and exceeded my expectations. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-06-27",
        "review_rate": 4.8,
        "customer_name": "Jeffrey Martin",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Sarah P.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-07-29",
        "review_rate": 4.9,
        "customer_name": "Heidi Mendoza",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Elizabeth J.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-07-28",
        "review_rate": 5.0,
        "customer_name": "Kristi Mann",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "James H.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-02-02",
        "review_rate": 4.9,
        "customer_name": "Gabrielle Allen",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Jamie D.",
        "review": "The technician was patient and understanding during the process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-08-08",
        "review_rate": 5.0,
        "customer_name": "John Thompson",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Kevin W.",
        "review": "The service was efficient and exceeded my expectations. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-09-21",
        "review_rate": 5.0,
        "customer_name": "Amanda Carter",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Michele W.",
        "review": "I was blown away by their level of expertise and service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-11-01",
        "review_rate": 4.9,
        "customer_name": "Jason Abbott",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Kevin H.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-12-14",
        "review_rate": 4.8,
        "customer_name": "Carla Russell",
        "service_type": "Garage Door Services",
        "tech_name": "Karen G.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-01-17",
        "review_rate": 5.0,
        "customer_name": "Janice Guerrero",
        "service_type": "Garage Door Off Track",
        "tech_name": "Joseph T.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-05-03",
        "review_rate": 4.8,
        "customer_name": "Jessica Gordon",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Jennifer H.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-03-11",
        "review_rate": 4.8,
        "customer_name": "Cynthia Hunt",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Thomas B.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-12-15",
        "review_rate": 5.0,
        "customer_name": "Rebecca Mendoza",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Jennifer B.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-11-06",
        "review_rate": 4.9,
        "customer_name": "Jordan Walker",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Ashley R.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-09-14",
        "review_rate": 5.0,
        "customer_name": "Gregory Davis",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Bradley P.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-12-25",
        "review_rate": 4.8,
        "customer_name": "Rebecca Anderson",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Kimberly B.",
        "review": "The technician was patient and understanding during the process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-07-31",
        "review_rate": 5.0,
        "customer_name": "Angela Mcdonald",
        "service_type": "Garage Door Repair",
        "tech_name": "Cole M.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-12-31",
        "review_rate": 5.0,
        "customer_name": "Jeffrey May",
        "service_type": "Garage Door Insulation",
        "tech_name": "Stephen J.",
        "review": "The technician was patient and understanding during the process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-07-26",
        "review_rate": 4.8,
        "customer_name": "Bryan Livingston",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Lisa K.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-04-13",
        "review_rate": 4.8,
        "customer_name": "Erin Young",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Tracey H.",
        "review": "I found their approach to customer service refreshing and effective. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-06-15",
        "review_rate": 4.8,
        "customer_name": "Jacqueline Blair",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Beth A.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-10-12",
        "review_rate": 5.0,
        "customer_name": "Kimberly Nguyen",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Melissa D.",
        "review": "The service exceeded my expectations in every way. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-12-03",
        "review_rate": 5.0,
        "customer_name": "Heather Quinn",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Nathan T.",
        "review": "Their attention to detail made all the difference in the outcome. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-09-05",
        "review_rate": 5.0,
        "customer_name": "John Matthews",
        "service_type": "Garage Door Opener",
        "tech_name": "Madison A.",
        "review": "I would recommend them to anyone needing garage door services. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-12-20",
        "review_rate": 5.0,
        "customer_name": "Theresa Spencer",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Sean S.",
        "review": "I can't believe how much better my garage door operates now. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-05-01",
        "review_rate": 5.0,
        "customer_name": "Jennifer Spencer",
        "service_type": "Garage Door Services",
        "tech_name": "Russell G.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-10-24",
        "review_rate": 4.8,
        "customer_name": "James Harmon",
        "service_type": "Garage Door Installation",
        "tech_name": "Courtney H.",
        "review": "I felt informed and confident about the repairs being made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-10-08",
        "review_rate": 4.9,
        "customer_name": "Mary Smith",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Jordan S.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-05-19",
        "review_rate": 4.8,
        "customer_name": "Zachary Moore",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Lisa M.",
        "review": "The technician was patient and understanding during the process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-09-02",
        "review_rate": 4.9,
        "customer_name": "Jason Bryant",
        "service_type": "Garage Door Services",
        "tech_name": "Kristen L.",
        "review": "I could tell they genuinely cared about their customers. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-08-29",
        "review_rate": 5.0,
        "customer_name": "Mario Lopez",
        "service_type": "Garage Door Repair",
        "tech_name": "Brian W.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-08-13",
        "review_rate": 5.0,
        "customer_name": "Nicole Dennis",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Julie M.",
        "review": "Their attention to detail made all the difference in the outcome. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-09-09",
        "review_rate": 4.9,
        "customer_name": "Brian Buckley",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Jorge P.",
        "review": "It was reassuring to see the technician\u2019s expertise in action. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-02-02",
        "review_rate": 5.0,
        "customer_name": "Autumn Velez",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Justin C.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-11-30",
        "review_rate": 4.9,
        "customer_name": "Joshua Morris",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Nicholas J.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-10-25",
        "review_rate": 4.9,
        "customer_name": "Jeremy Sanders",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Stephanie S.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-06-04",
        "review_rate": 5.0,
        "customer_name": "Richard Wells",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Jose F.",
        "review": "I can't express how satisfied I am with the garage door installation process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-03-10",
        "review_rate": 5.0,
        "customer_name": "Kathleen Bell",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Jill R.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-04-18",
        "review_rate": 4.9,
        "customer_name": "Rebecca Doyle",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Maria W.",
        "review": "I was blown away by their level of expertise and service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-02-20",
        "review_rate": 4.9,
        "customer_name": "Kristen Vang",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Stephanie S.",
        "review": "I can't recommend this garage door service enough after my experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-09-05",
        "review_rate": 4.9,
        "customer_name": "Sabrina Dixon",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Mike G.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-08-28",
        "review_rate": 4.9,
        "customer_name": "Mark Simpson",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Nicholas C.",
        "review": "I\u2019m very grateful for their outstanding customer support. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-01-18",
        "review_rate": 4.9,
        "customer_name": "Christopher Phillips",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Megan P.",
        "review": "The service was efficient and exceeded my expectations. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-06-13",
        "review_rate": 4.8,
        "customer_name": "Richard Craig",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Kevin C.",
        "review": "The technician's expertise was evident throughout the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-05-21",
        "review_rate": 4.9,
        "customer_name": "Brandy Sanders",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Becky R.",
        "review": "I couldn\u2019t be more satisfied with my garage door experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-11-21",
        "review_rate": 4.8,
        "customer_name": "Lisa Martinez",
        "service_type": "Garage Door Repair",
        "tech_name": "Aaron G.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-03-22",
        "review_rate": 4.8,
        "customer_name": "William Schmidt",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Norma W.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-04-04",
        "review_rate": 4.9,
        "customer_name": "Mrs. Sonya Adams DVM",
        "service_type": "Garage Door Insulation",
        "tech_name": "Laura B.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-03-06",
        "review_rate": 4.9,
        "customer_name": "Scott Horn",
        "service_type": "Garage Door Installation",
        "tech_name": "Cynthia L.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-12-02",
        "review_rate": 5.0,
        "customer_name": "Andrew Miller",
        "service_type": "Garage Door Insulation",
        "tech_name": "William M.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-10-09",
        "review_rate": 5.0,
        "customer_name": "David Woods",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Crystal P.",
        "review": "My garage door issue was resolved quickly and professionally. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-09-02",
        "review_rate": 4.9,
        "customer_name": "Eric Coffey",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Sherri S.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-05-05",
        "review_rate": 4.9,
        "customer_name": "Ryan King",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Cory S.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-07-17",
        "review_rate": 5.0,
        "customer_name": "Steven Hernandez",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Sean B.",
        "review": "I highly value the quick turnaround for my garage door repairs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-09-18",
        "review_rate": 4.8,
        "customer_name": "Mike Robbins",
        "service_type": "Garage Door Repair",
        "tech_name": "Mark T.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-04-12",
        "review_rate": 4.9,
        "customer_name": "Ashley Fleming",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Joshua S.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-05-21",
        "review_rate": 5.0,
        "customer_name": "Patrick Thomas",
        "service_type": "Garage Door Repair",
        "tech_name": "Eric J.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-06-04",
        "review_rate": 5.0,
        "customer_name": "Jason Munoz",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Alex E.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-04-17",
        "review_rate": 5.0,
        "customer_name": "Matthew Bailey",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Vanessa M.",
        "review": "I felt informed and confident about the repairs being made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-10-02",
        "review_rate": 4.9,
        "customer_name": "Matthew Webb",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Candice B.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-11-28",
        "review_rate": 4.9,
        "customer_name": "Victoria Lawson",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Yvonne M.",
        "review": "I had an emergency with my garage door, and they responded quickly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-07-19",
        "review_rate": 4.8,
        "customer_name": "David Russell",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Catherine A.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-07-16",
        "review_rate": 4.9,
        "customer_name": "Jordan Nash",
        "service_type": "Garage Door Opener",
        "tech_name": "Samuel G.",
        "review": "My garage door never worked better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-08-08",
        "review_rate": 4.8,
        "customer_name": "Andrew Harris",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Jacqueline B.",
        "review": "I was relieved to find such reliable service for my garage door issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-03-22",
        "review_rate": 4.9,
        "customer_name": "Dylan Carpenter",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "George W.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-11-22",
        "review_rate": 4.9,
        "customer_name": "Francis Gray",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Rachel G.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-03-13",
        "review_rate": 4.9,
        "customer_name": "Kevin Clark",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Terri L.",
        "review": "I couldn\u2019t be more satisfied with my garage door experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-03-15",
        "review_rate": 4.9,
        "customer_name": "Sara Clark",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "John W.",
        "review": "I was highly impressed with their professionalism throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-08-28",
        "review_rate": 5.0,
        "customer_name": "Eileen Gonzales",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Austin W.",
        "review": "I found their approach to customer service refreshing and effective. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-01-29",
        "review_rate": 4.9,
        "customer_name": "Daniel Arias",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Suzanne F.",
        "review": "The process was straightforward and hassle-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-03-01",
        "review_rate": 4.8,
        "customer_name": "Kelsey Holland",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Tina R.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-03-10",
        "review_rate": 4.9,
        "customer_name": "Tammie Fitzgerald",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "James H.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-08-15",
        "review_rate": 4.9,
        "customer_name": "Kayla Fuller",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Kelly J.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-08-28",
        "review_rate": 5.0,
        "customer_name": "Katie Smith",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Tammy M.",
        "review": "The service exceeded my expectations in every way. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-06-03",
        "review_rate": 4.9,
        "customer_name": "Austin Martinez",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Logan T.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-07-12",
        "review_rate": 4.9,
        "customer_name": "Joel Salazar",
        "service_type": "Garage Door Insulation",
        "tech_name": "Carol M.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-04-25",
        "review_rate": 4.9,
        "customer_name": "Danielle Sanchez",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "William C.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-09-24",
        "review_rate": 4.9,
        "customer_name": "Courtney Flowers",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Marcus M.",
        "review": "I was blown away by their level of expertise and service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-04-11",
        "review_rate": 4.8,
        "customer_name": "Amy Smith",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "George J.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-10-22",
        "review_rate": 4.9,
        "customer_name": "Samantha Meyer",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Riley H.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-03-06",
        "review_rate": 4.8,
        "customer_name": "Sonia Rogers",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Robert W.",
        "review": "The quality of their work is evident in the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-07-28",
        "review_rate": 4.8,
        "customer_name": "William Sellers",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Justin S.",
        "review": "The service was efficient and exceeded my expectations. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-12-28",
        "review_rate": 4.8,
        "customer_name": "Stephanie Henderson",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Edward S.",
        "review": "My experience with their garage door service was overwhelmingly positive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-05-30",
        "review_rate": 4.9,
        "customer_name": "Vanessa Anderson",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "John M.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-06-22",
        "review_rate": 5.0,
        "customer_name": "Jason George",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "David H.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-07-31",
        "review_rate": 5.0,
        "customer_name": "Allen Fletcher",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Kenneth M.",
        "review": "It was reassuring to see the technician\u2019s expertise in action. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-04-05",
        "review_rate": 5.0,
        "customer_name": "Steven Austin",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Allen S.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-02-03",
        "review_rate": 5.0,
        "customer_name": "Mary Ibarra",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Kayla S.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-12-03",
        "review_rate": 5.0,
        "customer_name": "Kathryn Manning",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Michael M.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-07-14",
        "review_rate": 4.8,
        "customer_name": "Megan Garcia",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Jeffrey F.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-08-03",
        "review_rate": 4.9,
        "customer_name": "Jose Mooney",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Amy W.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-05-04",
        "review_rate": 4.9,
        "customer_name": "Terri Smith",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Michelle V.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-03-06",
        "review_rate": 4.9,
        "customer_name": "Krystal Holland",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Paul B.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-02-22",
        "review_rate": 4.8,
        "customer_name": "Amy Owens",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Cristina R.",
        "review": "I couldn\u2019t be more satisfied with my garage door experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-09-13",
        "review_rate": 5.0,
        "customer_name": "David Hayes",
        "service_type": "Garage Door Opener",
        "tech_name": "James G.",
        "review": "The quality of their work is evident in the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-04-04",
        "review_rate": 4.8,
        "customer_name": "Sarah Ellis",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Nicole P.",
        "review": "The technician was courteous and very knowledgeable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-09-15",
        "review_rate": 4.9,
        "customer_name": "Samantha Jordan",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Troy C.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-04-04",
        "review_rate": 4.9,
        "customer_name": "Kathleen Butler",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Kristen G.",
        "review": "The technician was friendly and professional at all times. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-05-11",
        "review_rate": 4.8,
        "customer_name": "Debbie Mcdonald",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Jesse B.",
        "review": "The service felt personal and catered to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-01-27",
        "review_rate": 5.0,
        "customer_name": "Robert Parker",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Jake P.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-02-16",
        "review_rate": 5.0,
        "customer_name": "Madison Logan DDS",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Patrick W.",
        "review": "I was thoroughly impressed with their garage door safety inspection. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-04-27",
        "review_rate": 4.9,
        "customer_name": "Joseph Schaefer",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Chris R.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-07-11",
        "review_rate": 4.9,
        "customer_name": "Mario Mitchell",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Deborah J.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-05-09",
        "review_rate": 4.9,
        "customer_name": "Leroy Nguyen",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Lorraine J.",
        "review": "My garage door never worked better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-12-04",
        "review_rate": 4.9,
        "customer_name": "Emily Clark",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "David H.",
        "review": "I was surprised by how fast the technician completed the work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-02-11",
        "review_rate": 5.0,
        "customer_name": "James Mason",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Diane M.",
        "review": "I was surprised by how fast the technician completed the work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-09-20",
        "review_rate": 4.9,
        "customer_name": "Carla Carney",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Kevin C.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-05-10",
        "review_rate": 4.8,
        "customer_name": "Andrea Chandler",
        "service_type": "Garage Door Opener",
        "tech_name": "Yesenia H.",
        "review": "My garage door never worked better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-08-26",
        "review_rate": 4.9,
        "customer_name": "Alexis Baker",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Crystal D.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-05-30",
        "review_rate": 4.9,
        "customer_name": "Laura Klein",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Elizabeth N.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-02-11",
        "review_rate": 4.8,
        "customer_name": "William Parker",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Jeffery G.",
        "review": "I was surprised by how fast the technician completed the work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-10-15",
        "review_rate": 4.9,
        "customer_name": "Ashley Perkins",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Kimberly R.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-12-12",
        "review_rate": 4.9,
        "customer_name": "Mary Sanford",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Christopher L.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-06-23",
        "review_rate": 5.0,
        "customer_name": "Denise Williams",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Richard J.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-10-14",
        "review_rate": 4.8,
        "customer_name": "Janet Moore",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Abigail H.",
        "review": "The technician took great care in performing the job correctly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-02-21",
        "review_rate": 4.8,
        "customer_name": "Cynthia Hughes",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Eric C.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-10-15",
        "review_rate": 4.9,
        "customer_name": "Sandy Powers",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Sarah R.",
        "review": "My garage door issue was resolved quickly and professionally. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-07-02",
        "review_rate": 4.8,
        "customer_name": "Madison Smith",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Tammy T.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-06-01",
        "review_rate": 4.8,
        "customer_name": "Wendy Bell",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Christina Y.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-11-08",
        "review_rate": 4.8,
        "customer_name": "Katelyn Rocha",
        "service_type": "Garage Door Insulation",
        "tech_name": "John A.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-04-14",
        "review_rate": 5.0,
        "customer_name": "Tiffany Acosta",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "David R.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-04-30",
        "review_rate": 4.8,
        "customer_name": "Erica Gentry",
        "service_type": "Garage Door Installation",
        "tech_name": "Jack R.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-10-22",
        "review_rate": 4.8,
        "customer_name": "Regina Rowland",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Kristin C.",
        "review": "I can't believe how much better my garage door operates now. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-04-15",
        "review_rate": 4.8,
        "customer_name": "Rodney Harrison",
        "service_type": "Garage Door Installation",
        "tech_name": "Christine F.",
        "review": "The whole process felt easy and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-12-02",
        "review_rate": 4.9,
        "customer_name": "Meghan Morris",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Mckenzie I.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-06-08",
        "review_rate": 4.9,
        "customer_name": "Jill Fry",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Michael R.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-11-01",
        "review_rate": 4.8,
        "customer_name": "Linda Hansen",
        "service_type": "Garage Door Installation",
        "tech_name": "Ashley W.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-08-26",
        "review_rate": 4.8,
        "customer_name": "Cole Pratt",
        "service_type": "Garage Door Installation",
        "tech_name": "Daniel E.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-06-15",
        "review_rate": 5.0,
        "customer_name": "Amy Lynch",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Chelsea T.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-09-10",
        "review_rate": 4.8,
        "customer_name": "Martha Hood",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Nicholas W.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-03-16",
        "review_rate": 4.8,
        "customer_name": "Marvin Cruz",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Kendra A.",
        "review": "The whole process felt easy and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-07-30",
        "review_rate": 4.9,
        "customer_name": "Jacqueline Hurst",
        "service_type": "Garage Door Opener",
        "tech_name": "Joseph G.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-05-21",
        "review_rate": 5.0,
        "customer_name": "Scott Rivera",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Johnny W.",
        "review": "The quality of their work is evident in the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-10-05",
        "review_rate": 4.9,
        "customer_name": "Ashley Estrada",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Dylan G.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-07-10",
        "review_rate": 5.0,
        "customer_name": "Sarah Nguyen",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "William M.",
        "review": "I found their approach to customer service refreshing and effective. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-04-27",
        "review_rate": 4.9,
        "customer_name": "Eric Simmons",
        "service_type": "Garage Door Off Track",
        "tech_name": "Joseph E.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-02-23",
        "review_rate": 5.0,
        "customer_name": "Mary Odom",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Brandon P.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-01-01",
        "review_rate": 4.8,
        "customer_name": "David Christensen IV",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Krista B.",
        "review": "The technician was patient and understanding during the process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-01-30",
        "review_rate": 4.9,
        "customer_name": "Lawrence Gomez",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Jill B.",
        "review": "I highly value the quick turnaround for my garage door repairs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-03-03",
        "review_rate": 4.9,
        "customer_name": "Steven Williams",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Virginia P.",
        "review": "I felt reassured by their knowledge and experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-10-31",
        "review_rate": 4.9,
        "customer_name": "Elizabeth Mullins",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Jeffrey A.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-07-08",
        "review_rate": 4.8,
        "customer_name": "April Scott",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Lisa M.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-03-30",
        "review_rate": 4.8,
        "customer_name": "Amy Lopez",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Spencer W.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-01-13",
        "review_rate": 4.8,
        "customer_name": "Dominique Ibarra",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Richard W.",
        "review": "I was blown away by how quickly the technician fixed my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-09-08",
        "review_rate": 4.8,
        "customer_name": "Brian Pena",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Nicole R.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-01-29",
        "review_rate": 5.0,
        "customer_name": "Autumn Velazquez",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Rhonda S.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-02-16",
        "review_rate": 4.9,
        "customer_name": "Ricardo Estrada",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Leslie I.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-06-17",
        "review_rate": 4.9,
        "customer_name": "Beverly Gillespie",
        "service_type": "Garage Door Off Track",
        "tech_name": "Adam M.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-09-02",
        "review_rate": 4.9,
        "customer_name": "Rachael Anderson",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Michael R.",
        "review": "I was surprised by how fast the technician completed the work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-09-03",
        "review_rate": 5.0,
        "customer_name": "Robert Boyle",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Terry M.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-01-24",
        "review_rate": 5.0,
        "customer_name": "Julia Howell",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "John K.",
        "review": "This service was exactly what I needed for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-10-17",
        "review_rate": 4.8,
        "customer_name": "Robert Grimes",
        "service_type": "Garage Door Opener",
        "tech_name": "Jose P.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-09-18",
        "review_rate": 4.9,
        "customer_name": "Steven Benton",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Samuel G.",
        "review": "I felt informed and confident about the repairs being made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-04-04",
        "review_rate": 4.9,
        "customer_name": "Donna Daniels",
        "service_type": "Garage Door Insulation",
        "tech_name": "Gavin M.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-07-20",
        "review_rate": 4.8,
        "customer_name": "Stephen Shaffer",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Cameron B.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-09-11",
        "review_rate": 4.8,
        "customer_name": "Richard Livingston",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Amanda H.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-03-09",
        "review_rate": 4.8,
        "customer_name": "Janice Roberts",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Kimberly W.",
        "review": "I was grateful for their quick response to my garage door emergency. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-03-31",
        "review_rate": 4.9,
        "customer_name": "Brenda Livingston",
        "service_type": "Garage Door Opener",
        "tech_name": "Daniel V.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-01-19",
        "review_rate": 4.9,
        "customer_name": "Alan Abbott",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Jill G.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-02-25",
        "review_rate": 4.9,
        "customer_name": "Christina Perry",
        "service_type": "Garage Door Insulation",
        "tech_name": "Teresa J.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-12-26",
        "review_rate": 4.9,
        "customer_name": "Jimmy Sanders",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Nicholas A.",
        "review": "I was relieved to find such reliable service for my garage door issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-10-22",
        "review_rate": 4.9,
        "customer_name": "Peter Owens",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Edward C.",
        "review": "The technician was courteous and very knowledgeable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-02-08",
        "review_rate": 4.9,
        "customer_name": "Steven Adams",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Barbara D.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-06-21",
        "review_rate": 4.8,
        "customer_name": "Eugene Cooper",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Debra W.",
        "review": "The crew was friendly and very efficient with the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-12-31",
        "review_rate": 4.8,
        "customer_name": "Lisa Singh",
        "service_type": "Garage Door Repair",
        "tech_name": "Emily M.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-04-22",
        "review_rate": 4.8,
        "customer_name": "Terri Wilson",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Nancy W.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-07-28",
        "review_rate": 4.9,
        "customer_name": "Anne Wolfe",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "James G.",
        "review": "I was blown away by how quickly the technician fixed my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-04-27",
        "review_rate": 4.8,
        "customer_name": "Kelly Foster",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Christian H.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-09-02",
        "review_rate": 4.8,
        "customer_name": "Jesse Howard",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Melissa W.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-05-08",
        "review_rate": 4.9,
        "customer_name": "Ruth Cross",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Sandra H.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-06-16",
        "review_rate": 4.9,
        "customer_name": "Daniel Sanders",
        "service_type": "Garage Door Services",
        "tech_name": "Jermaine D.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-01-06",
        "review_rate": 4.9,
        "customer_name": "Tristan Sims",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Julie G.",
        "review": "I appreciated the thorough explanation of the work being done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-08-28",
        "review_rate": 4.9,
        "customer_name": "Jerry Hampton",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Laura H.",
        "review": "I was blown away by their level of expertise and service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-10-09",
        "review_rate": 4.9,
        "customer_name": "Anthony Woods",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Stephen W.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-01-20",
        "review_rate": 4.9,
        "customer_name": "Christopher Davis",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Christopher R.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-02-15",
        "review_rate": 4.9,
        "customer_name": "Clarence Anderson",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Jennifer H.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-05-27",
        "review_rate": 4.9,
        "customer_name": "Denise Henderson",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Michelle M.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-04-21",
        "review_rate": 4.9,
        "customer_name": "Jeremy Garner MD",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Lucas M.",
        "review": "I was thoroughly impressed with their garage door safety inspection. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-12-19",
        "review_rate": 4.8,
        "customer_name": "Mary Diaz",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Patricia T.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-02-17",
        "review_rate": 4.9,
        "customer_name": "Stephanie Donovan",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Laura H.",
        "review": "I would recommend them to anyone needing garage door services. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-11-15",
        "review_rate": 5.0,
        "customer_name": "Chris Cruz",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Vicki T.",
        "review": "I appreciated the straightforward communication during my service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-10-04",
        "review_rate": 4.9,
        "customer_name": "Joshua Gallagher",
        "service_type": "Garage Door Repair",
        "tech_name": "Makayla S.",
        "review": "My garage door never worked better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-01-28",
        "review_rate": 5.0,
        "customer_name": "Stephanie Walsh",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Thomas D.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-04-13",
        "review_rate": 4.9,
        "customer_name": "Paula Smith",
        "service_type": "Garage Door Insulation",
        "tech_name": "Laura R.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-04-18",
        "review_rate": 5.0,
        "customer_name": "Anthony Richardson",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Adam W.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-10-22",
        "review_rate": 4.9,
        "customer_name": "Judy Watkins",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Janice B.",
        "review": "I felt confident in their abilities after seeing the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-05-05",
        "review_rate": 4.9,
        "customer_name": "Erin Mills",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Luis D.",
        "review": "The quality of their work is evident in the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-11-16",
        "review_rate": 4.9,
        "customer_name": "Monica Flores",
        "service_type": "Garage Door Services",
        "tech_name": "Cameron C.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-06-26",
        "review_rate": 5.0,
        "customer_name": "Jerry Smith",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Robert M.",
        "review": "I highly value the quick turnaround for my garage door repairs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-08-04",
        "review_rate": 4.9,
        "customer_name": "Stephanie Cline",
        "service_type": "Garage Door Off Track",
        "tech_name": "Francis J.",
        "review": "I was surprised by how fast the technician completed the work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-12-31",
        "review_rate": 4.9,
        "customer_name": "Adrienne Rodriguez",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Joseph B.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-07-24",
        "review_rate": 4.9,
        "customer_name": "Zachary Bray",
        "service_type": "Garage Door Repair",
        "tech_name": "Charles R.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-10-05",
        "review_rate": 4.9,
        "customer_name": "Elizabeth Johnson",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Hannah B.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-09-08",
        "review_rate": 5.0,
        "customer_name": "Daniel Bell",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Roger H.",
        "review": "I appreciate how they kept me informed throughout the repair process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-12-11",
        "review_rate": 4.9,
        "customer_name": "Lindsey Lucas",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Seth R.",
        "review": "I found their approach to customer service refreshing and effective. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-08-07",
        "review_rate": 5.0,
        "customer_name": "Raymond Wright",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Bobby C.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-01-19",
        "review_rate": 5.0,
        "customer_name": "Corey Myers",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Katherine B.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-05-23",
        "review_rate": 4.9,
        "customer_name": "Erik Roach",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Jeffrey D.",
        "review": "They handled my garage door issues with expertise and care. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-03-02",
        "review_rate": 5.0,
        "customer_name": "Russell Kennedy",
        "service_type": "Garage Door Installation",
        "tech_name": "Peter O.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-06-11",
        "review_rate": 4.9,
        "customer_name": "Laurie Ramirez",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Cindy J.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-09-23",
        "review_rate": 5.0,
        "customer_name": "Andrea Durham",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Stacy P.",
        "review": "The process was straightforward and hassle-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-08-04",
        "review_rate": 4.9,
        "customer_name": "Chad Collins",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Leah C.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-09-26",
        "review_rate": 4.9,
        "customer_name": "Tiffany Hale",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Victor B.",
        "review": "My garage door never worked better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-02-02",
        "review_rate": 4.9,
        "customer_name": "Nancy Miller",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Ryan K.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-12-25",
        "review_rate": 4.8,
        "customer_name": "Wendy Wright",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Pamela M.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-07-11",
        "review_rate": 4.8,
        "customer_name": "Daniel Armstrong",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Tammy R.",
        "review": "I felt valued as a customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-04-05",
        "review_rate": 4.8,
        "customer_name": "Debra Austin",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Danielle H.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-06-29",
        "review_rate": 4.8,
        "customer_name": "Alexander Jones",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Teresa F.",
        "review": "I was blown away by how quickly the technician fixed my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-05-10",
        "review_rate": 4.9,
        "customer_name": "Paul Fisher",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Emily R.",
        "review": "It was reassuring to see the technician\u2019s expertise in action. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-10-15",
        "review_rate": 4.9,
        "customer_name": "Tamara Rodriguez",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Jennifer B.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-01-14",
        "review_rate": 4.8,
        "customer_name": "William Sanchez",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Jordan P.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-06-30",
        "review_rate": 5.0,
        "customer_name": "Joseph Kim",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Mary T.",
        "review": "I can't recommend this garage door service enough after my experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-09-18",
        "review_rate": 4.9,
        "customer_name": "Sharon Walsh",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Christopher P.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-12-08",
        "review_rate": 4.9,
        "customer_name": "Jenny Nunez",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Christopher B.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-06-11",
        "review_rate": 4.9,
        "customer_name": "Robert Callahan",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Drew H.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-02-13",
        "review_rate": 5.0,
        "customer_name": "Haley Scott",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Deborah W.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-07-15",
        "review_rate": 4.8,
        "customer_name": "Anthony Benjamin",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Jessica E.",
        "review": "I felt informed and confident about the repairs being made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-11-29",
        "review_rate": 5.0,
        "customer_name": "Michael Mercer",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Holly G.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-02-26",
        "review_rate": 4.8,
        "customer_name": "Steve Hansen",
        "service_type": "Garage Door Insulation",
        "tech_name": "Larry M.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-01-31",
        "review_rate": 4.9,
        "customer_name": "Jonathan Alexander",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Amanda L.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-02-28",
        "review_rate": 5.0,
        "customer_name": "Kristin Chavez",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Thomas D.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-08-26",
        "review_rate": 4.9,
        "customer_name": "Michael Castaneda",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Annette B.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-06-02",
        "review_rate": 4.9,
        "customer_name": "Jeffrey Fisher",
        "service_type": "Garage Door Insulation",
        "tech_name": "Shawn A.",
        "review": "The crew was friendly and very efficient with the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-12-04",
        "review_rate": 4.9,
        "customer_name": "Tamara Booth",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Belinda B.",
        "review": "I was highly impressed with their professionalism throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-02-08",
        "review_rate": 5.0,
        "customer_name": "Kimberly Martinez",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Colleen M.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-11-25",
        "review_rate": 4.9,
        "customer_name": "Kayla Garcia",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Suzanne N.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-09-10",
        "review_rate": 4.9,
        "customer_name": "Pamela Ho",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Patrick K.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-10-16",
        "review_rate": 4.9,
        "customer_name": "Dakota Beck",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Rodney G.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-01-04",
        "review_rate": 4.8,
        "customer_name": "Carl Johnston",
        "service_type": "Garage Door Insulation",
        "tech_name": "Christopher N.",
        "review": "I was highly impressed with their professionalism throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-11-30",
        "review_rate": 4.9,
        "customer_name": "Audrey Haynes",
        "service_type": "Garage Door Insulation",
        "tech_name": "Noah W.",
        "review": "The crew was friendly and very efficient with the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-06-30",
        "review_rate": 5.0,
        "customer_name": "Jordan Miller",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Kayla S.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-03-25",
        "review_rate": 5.0,
        "customer_name": "Kevin White",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Robert T.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-08-15",
        "review_rate": 4.8,
        "customer_name": "Morgan Campbell",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Katrina W.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-12-02",
        "review_rate": 4.9,
        "customer_name": "Cody Cameron",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Patrick D.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-08-13",
        "review_rate": 4.8,
        "customer_name": "Virginia Pena",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Kimberly W.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-12-13",
        "review_rate": 4.9,
        "customer_name": "Mario Walker Jr.",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Kelsey W.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-05-24",
        "review_rate": 5.0,
        "customer_name": "Christopher Franklin",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Stephen O.",
        "review": "I felt valued as a customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-07-20",
        "review_rate": 4.8,
        "customer_name": "James White",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Julie F.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-03-13",
        "review_rate": 4.9,
        "customer_name": "Jerry Bennett",
        "service_type": "Garage Door Installation",
        "tech_name": "Richard C.",
        "review": "The level of professionalism displayed was remarkable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-06-15",
        "review_rate": 4.9,
        "customer_name": "Kelly Zavala",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Christopher B.",
        "review": "I felt reassured by their knowledge and experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-10-10",
        "review_rate": 4.9,
        "customer_name": "Regina Blackwell",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Keith C.",
        "review": "Their attention to detail made all the difference in the outcome. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-10-15",
        "review_rate": 4.9,
        "customer_name": "Melanie Collins",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Valerie H.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-02-25",
        "review_rate": 5.0,
        "customer_name": "Samuel Smith",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Joshua W.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-09-30",
        "review_rate": 4.8,
        "customer_name": "Amy Sullivan",
        "service_type": "Garage Door Track Repair",
        "tech_name": "David H.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-10-03",
        "review_rate": 4.9,
        "customer_name": "Madeline Norris",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Debra B.",
        "review": "I couldn\u2019t be more satisfied with my garage door experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-05-09",
        "review_rate": 4.9,
        "customer_name": "Devin Kirk",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Timothy H.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-08-04",
        "review_rate": 4.9,
        "customer_name": "Daniel Sanchez",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Brenda F.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-06-06",
        "review_rate": 4.9,
        "customer_name": "Jennifer Hester",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Rebecca F.",
        "review": "I was relieved to find such reliable service for my garage door issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-10-16",
        "review_rate": 5.0,
        "customer_name": "Jenna Greer",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Cheryl M.",
        "review": "The level of professionalism displayed was remarkable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-09-06",
        "review_rate": 4.8,
        "customer_name": "Connie Boyd MD",
        "service_type": "Garage Door Services",
        "tech_name": "Rachel R.",
        "review": "It was reassuring to see the technician\u2019s expertise in action. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-01-31",
        "review_rate": 5.0,
        "customer_name": "Austin Wright DDS",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Michael M.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-04-15",
        "review_rate": 5.0,
        "customer_name": "Albert Gomez",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Jennifer C.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-12-15",
        "review_rate": 4.8,
        "customer_name": "Amanda White",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Anita T.",
        "review": "The service exceeded my expectations in every way. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-12-05",
        "review_rate": 4.9,
        "customer_name": "Jessica Lin",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Derek C.",
        "review": "I was highly impressed with their professionalism throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-07-26",
        "review_rate": 4.9,
        "customer_name": "Mary Sullivan",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Tracey H.",
        "review": "The whole process felt easy and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-11-11",
        "review_rate": 4.9,
        "customer_name": "Tami Diaz",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Brian B.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-07-01",
        "review_rate": 4.9,
        "customer_name": "Sydney Harvey",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Katherine M.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-08-07",
        "review_rate": 4.8,
        "customer_name": "Melissa Martin",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Angela B.",
        "review": "Having my garage door repaired was a smooth experience thanks to the team. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-04-01",
        "review_rate": 4.8,
        "customer_name": "Jeffrey Rocha",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Kelly H.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-08-04",
        "review_rate": 4.9,
        "customer_name": "Philip Wilkins",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Gary P.",
        "review": "The technician took great care in performing the job correctly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-03-24",
        "review_rate": 4.9,
        "customer_name": "Keith Walton",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Matthew C.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-06-21",
        "review_rate": 5.0,
        "customer_name": "Nicole Dickerson",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Jacob C.",
        "review": "My garage door never worked better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-05-04",
        "review_rate": 5.0,
        "customer_name": "William Rodriguez",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Brian P.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-12-19",
        "review_rate": 4.9,
        "customer_name": "Daniel Greer",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Alyssa J.",
        "review": "My garage door never worked better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-07-29",
        "review_rate": 5.0,
        "customer_name": "Shannon Martin",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Adrian H.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-05-07",
        "review_rate": 4.9,
        "customer_name": "David Williams",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Shawna R.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-10-16",
        "review_rate": 4.9,
        "customer_name": "John Cohen",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Katherine M.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-07-14",
        "review_rate": 4.9,
        "customer_name": "Joshua Stephens",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Emily H.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-09-16",
        "review_rate": 4.9,
        "customer_name": "Kelly Murphy",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Pedro J.",
        "review": "This company has set the standard for garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-07-24",
        "review_rate": 5.0,
        "customer_name": "Robert Brooks",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Ricky B.",
        "review": "I felt informed and confident about the repairs being made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-06-05",
        "review_rate": 4.9,
        "customer_name": "Melanie Jones",
        "service_type": "Garage Door Insulation",
        "tech_name": "Brianna M.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-10-01",
        "review_rate": 4.9,
        "customer_name": "Sara Adams",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Stacy V.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-06-03",
        "review_rate": 4.9,
        "customer_name": "Kristina Morris",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Anthony M.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-03-12",
        "review_rate": 4.9,
        "customer_name": "Jennifer Patel",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Laura D.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-08-11",
        "review_rate": 4.9,
        "customer_name": "David George",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Laurie W.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-01-13",
        "review_rate": 4.9,
        "customer_name": "Vincent Graham",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Zachary M.",
        "review": "They handled my garage door issues with expertise and care. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-12-19",
        "review_rate": 4.9,
        "customer_name": "Richard Hill",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Jacqueline H.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-04-04",
        "review_rate": 4.9,
        "customer_name": "Lauren Giles",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Kristen M.",
        "review": "The level of professionalism displayed was remarkable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-03-21",
        "review_rate": 4.9,
        "customer_name": "Kristy Bailey",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Ashley W.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-11-28",
        "review_rate": 5.0,
        "customer_name": "Crystal Mercer",
        "service_type": "Garage Door Insulation",
        "tech_name": "Ashley F.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-08-12",
        "review_rate": 4.9,
        "customer_name": "John Johnson",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Jonathan M.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-01-03",
        "review_rate": 4.9,
        "customer_name": "Donna Dean",
        "service_type": "Garage Door Opener",
        "tech_name": "Alexa S.",
        "review": "I was blown away by how quickly the technician fixed my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-04-28",
        "review_rate": 5.0,
        "customer_name": "Russell Walsh",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Vanessa M.",
        "review": "I was surprised by how fast the technician completed the work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-02-24",
        "review_rate": 5.0,
        "customer_name": "Roy White",
        "service_type": "Garage Door Repair",
        "tech_name": "Paula C.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-06-14",
        "review_rate": 4.9,
        "customer_name": "Andrew Campbell",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Justin P.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-05-11",
        "review_rate": 4.9,
        "customer_name": "Joseph Huffman",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Courtney V.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-06-06",
        "review_rate": 4.9,
        "customer_name": "Linda Liu DVM",
        "service_type": "Garage Door Installation",
        "tech_name": "Richard P.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-03-23",
        "review_rate": 4.9,
        "customer_name": "Richard Lam",
        "service_type": "Garage Door Services",
        "tech_name": "Connie W.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-03-23",
        "review_rate": 5.0,
        "customer_name": "Julie Weiss",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Alan H.",
        "review": "I was surprised by how fast the technician completed the work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-12-14",
        "review_rate": 4.9,
        "customer_name": "Cody Wagner",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Kelsey M.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-07-14",
        "review_rate": 4.9,
        "customer_name": "Amanda Gray",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Kelly B.",
        "review": "I appreciated the straightforward communication during my service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-11-22",
        "review_rate": 4.9,
        "customer_name": "Angela Richardson",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "David M.",
        "review": "The quality of their work is evident in the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-01-30",
        "review_rate": 4.9,
        "customer_name": "Kristin Sandoval",
        "service_type": "Garage Door Off Track",
        "tech_name": "Russell T.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-03-21",
        "review_rate": 4.9,
        "customer_name": "Breanna White",
        "service_type": "Garage Door Installation",
        "tech_name": "Penny H.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-02-15",
        "review_rate": 4.9,
        "customer_name": "Nicole Richard",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Rachel W.",
        "review": "I felt valued as a customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-07-03",
        "review_rate": 4.8,
        "customer_name": "David Mcneil",
        "service_type": "Garage Door Opener",
        "tech_name": "Lawrence O.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-02-11",
        "review_rate": 5.0,
        "customer_name": "Mr. Sean Pope PhD",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Scott B.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-03-19",
        "review_rate": 5.0,
        "customer_name": "Sarah Edwards",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Sara B.",
        "review": "The technician was courteous and very knowledgeable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-12-23",
        "review_rate": 4.9,
        "customer_name": "Jorge Norris",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Jeffrey P.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-03-31",
        "review_rate": 5.0,
        "customer_name": "Barbara Willis",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Kimberly L.",
        "review": "I would recommend them to anyone needing garage door services. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-04-12",
        "review_rate": 5.0,
        "customer_name": "Patty Cooper",
        "service_type": "Garage Door Off Track",
        "tech_name": "Stephanie C.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-02-29",
        "review_rate": 4.8,
        "customer_name": "Stacy Williams",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Lisa J.",
        "review": "This service was exactly what I needed for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-06-01",
        "review_rate": 4.9,
        "customer_name": "Robert Gonzalez",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "David K.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-04-03",
        "review_rate": 5.0,
        "customer_name": "Julia Elliott",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Paul R.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-03-18",
        "review_rate": 4.8,
        "customer_name": "Katelyn Roth",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Dawn S.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-08-30",
        "review_rate": 5.0,
        "customer_name": "Jason Wade",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Nicholas O.",
        "review": "I was grateful for their quick response to my garage door emergency. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-03-24",
        "review_rate": 4.8,
        "customer_name": "Ashley Ramirez",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Sandra H.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-02-15",
        "review_rate": 4.9,
        "customer_name": "Michael Kelly",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Cody B.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-01-02",
        "review_rate": 4.9,
        "customer_name": "Mary Gordon",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Alexis M.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-12-22",
        "review_rate": 4.8,
        "customer_name": "Roberto Thompson",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "George E.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-10-14",
        "review_rate": 5.0,
        "customer_name": "Heather Gibbs",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Christopher C.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-06-19",
        "review_rate": 4.9,
        "customer_name": "David Contreras",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Andrea P.",
        "review": "I was thoroughly impressed with their garage door safety inspection. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-12-03",
        "review_rate": 4.9,
        "customer_name": "Derek Powell",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Douglas G.",
        "review": "I can't express how satisfied I am with the garage door installation process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-03-04",
        "review_rate": 4.9,
        "customer_name": "Mark Marshall",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Morgan J.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-01-29",
        "review_rate": 4.9,
        "customer_name": "Kyle Jones",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Taylor S.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-06-16",
        "review_rate": 4.9,
        "customer_name": "Jessica Gonzalez",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Sandra L.",
        "review": "The process was straightforward and hassle-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-04-29",
        "review_rate": 4.9,
        "customer_name": "Gary Goodwin",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Gary B.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-11-28",
        "review_rate": 5.0,
        "customer_name": "Casey Price",
        "service_type": "Garage Door Insulation",
        "tech_name": "Cody H.",
        "review": "My garage door issue was resolved quickly and professionally. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-02-18",
        "review_rate": 5.0,
        "customer_name": "Stacy Cortez",
        "service_type": "Garage Door Repair",
        "tech_name": "Tonya W.",
        "review": "I felt valued as a customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-05-07",
        "review_rate": 4.9,
        "customer_name": "Susan Williams",
        "service_type": "Garage Door Opener",
        "tech_name": "Jesse H.",
        "review": "I could tell they genuinely cared about their customers. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-02-28",
        "review_rate": 5.0,
        "customer_name": "Walter Smith",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Stacy P.",
        "review": "The service exceeded my expectations in every way. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-07-06",
        "review_rate": 5.0,
        "customer_name": "Brian Reed",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Ariana H.",
        "review": "I appreciate how they kept me informed throughout the repair process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-11-30",
        "review_rate": 4.9,
        "customer_name": "Troy Taylor",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Nathan M.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-10-12",
        "review_rate": 4.9,
        "customer_name": "Charles Lewis",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Bradley W.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-03-26",
        "review_rate": 4.8,
        "customer_name": "Steven Hall",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Kari L.",
        "review": "The crew was friendly and very efficient with the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-04-26",
        "review_rate": 4.9,
        "customer_name": "Douglas Miller",
        "service_type": "Garage Door Installation",
        "tech_name": "David M.",
        "review": "The quality of their work is evident in the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-12-13",
        "review_rate": 4.8,
        "customer_name": "James Fisher",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Susan C.",
        "review": "The process was straightforward and hassle-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-02-09",
        "review_rate": 4.9,
        "customer_name": "Jamie Collins",
        "service_type": "Garage Door Services",
        "tech_name": "Brian G.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-08-14",
        "review_rate": 5.0,
        "customer_name": "Gina Hampton",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Sandra F.",
        "review": "The technician made sure I understood all my options. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-10-09",
        "review_rate": 4.8,
        "customer_name": "Jenna Hill",
        "service_type": "Garage Door Insulation",
        "tech_name": "Ashley W.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-06-06",
        "review_rate": 5.0,
        "customer_name": "Francisco Thompson",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Gina P.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-06-02",
        "review_rate": 4.9,
        "customer_name": "Mr. Daniel Rice",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Connor G.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-10-30",
        "review_rate": 4.9,
        "customer_name": "Vanessa Obrien",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Erika C.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-10-27",
        "review_rate": 4.9,
        "customer_name": "Jennifer Brown",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Collin A.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-01-26",
        "review_rate": 4.9,
        "customer_name": "Melanie Freeman",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Barbara W.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-01-16",
        "review_rate": 5.0,
        "customer_name": "Henry Carter",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Cindy R.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-04-05",
        "review_rate": 4.9,
        "customer_name": "Aaron Garrison",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "John O.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-11-24",
        "review_rate": 5.0,
        "customer_name": "Michelle Bradford",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Julian T.",
        "review": "The technician was friendly and professional at all times. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-11-15",
        "review_rate": 5.0,
        "customer_name": "Michelle Flowers",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Kimberly R.",
        "review": "I was highly impressed with their professionalism throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-02-25",
        "review_rate": 4.8,
        "customer_name": "Tammie Baker",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Amy T.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-06-28",
        "review_rate": 5.0,
        "customer_name": "Sarah Green",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Jennifer M.",
        "review": "My garage door issue was resolved quickly and professionally. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-02-06",
        "review_rate": 4.8,
        "customer_name": "Sandra Thompson",
        "service_type": "Garage Door Repair",
        "tech_name": "Gina Y.",
        "review": "The technician made sure I understood all my options. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-06-20",
        "review_rate": 4.9,
        "customer_name": "Danielle Gonzalez DDS",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Ian M.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-01-09",
        "review_rate": 4.9,
        "customer_name": "John Hubbard",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Emily B.",
        "review": "This company has set the standard for garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-08-09",
        "review_rate": 4.9,
        "customer_name": "Lori Garcia",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Juan P.",
        "review": "The quality of their work is evident in the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-03-09",
        "review_rate": 4.8,
        "customer_name": "Mr. Dakota Weaver",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Christina R.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-01-24",
        "review_rate": 4.9,
        "customer_name": "Matthew Mcneil",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Nicole W.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-12-19",
        "review_rate": 5.0,
        "customer_name": "Scott Collins",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Jennifer G.",
        "review": "The technician made sure I understood all my options. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-07-13",
        "review_rate": 4.9,
        "customer_name": "Karen Jacobs",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Brandi C.",
        "review": "The follow-up service was just as excellent as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-05-09",
        "review_rate": 5.0,
        "customer_name": "Ronald Foster",
        "service_type": "Garage Door Installation",
        "tech_name": "Gerald A.",
        "review": "I was blown away by how quickly the technician fixed my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-05-23",
        "review_rate": 4.8,
        "customer_name": "Justin Rodriguez MD",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Tina L.",
        "review": "I would recommend them to anyone needing garage door services. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-05-16",
        "review_rate": 4.8,
        "customer_name": "Jeanne Sanchez",
        "service_type": "Garage Door Opener",
        "tech_name": "Vickie H.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-04-10",
        "review_rate": 4.9,
        "customer_name": "Joseph Munoz III",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Heidi W.",
        "review": "I felt informed and confident about the repairs being made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-06-09",
        "review_rate": 4.8,
        "customer_name": "Valerie Ruiz",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Bryan M.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-10-01",
        "review_rate": 4.9,
        "customer_name": "Brian Payne",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Zachary B.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-11-18",
        "review_rate": 5.0,
        "customer_name": "Amber Alexander DVM",
        "service_type": "Garage Door Opener",
        "tech_name": "Dennis B.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-04-22",
        "review_rate": 5.0,
        "customer_name": "Aaron Coleman",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Matthew M.",
        "review": "My garage door never worked better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-11-14",
        "review_rate": 4.9,
        "customer_name": "Amber Craig",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Thomas M.",
        "review": "I was blown away by how quickly the technician fixed my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-10-20",
        "review_rate": 4.8,
        "customer_name": "Lisa Davis",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Valerie C.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-10-23",
        "review_rate": 4.9,
        "customer_name": "Ashley Mccullough",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Michael M.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-09-25",
        "review_rate": 4.8,
        "customer_name": "Steven Williams",
        "service_type": "Garage Door Installation",
        "tech_name": "Brian M.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-05-26",
        "review_rate": 5.0,
        "customer_name": "Joseph Morris",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Claudia S.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-11-05",
        "review_rate": 4.9,
        "customer_name": "Jennifer Valdez",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Jeremy R.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-05-11",
        "review_rate": 4.9,
        "customer_name": "Tonya Smith",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Cynthia S.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-08-26",
        "review_rate": 4.9,
        "customer_name": "James Matthews",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Mackenzie P.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-10-22",
        "review_rate": 4.9,
        "customer_name": "Stephanie Mooney",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Darlene J.",
        "review": "I was highly impressed with their professionalism throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-03-23",
        "review_rate": 4.8,
        "customer_name": "Joseph Wilkins",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Patricia G.",
        "review": "The process was straightforward and hassle-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-11-29",
        "review_rate": 4.9,
        "customer_name": "Dylan Pope",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Kathleen H.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-09-26",
        "review_rate": 4.9,
        "customer_name": "Robert Anderson",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Gregory D.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-11-04",
        "review_rate": 4.9,
        "customer_name": "Kayla Greene",
        "service_type": "Garage Door Insulation",
        "tech_name": "Angela B.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-09-02",
        "review_rate": 4.9,
        "customer_name": "Stacy Brown",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Michelle J.",
        "review": "The technician's expertise was evident throughout the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-05-04",
        "review_rate": 4.9,
        "customer_name": "Colleen Conrad",
        "service_type": "Garage Door Opener",
        "tech_name": "Martin R.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-09-09",
        "review_rate": 4.9,
        "customer_name": "Bob Reed",
        "service_type": "Garage Door Services",
        "tech_name": "Brittney M.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-08-05",
        "review_rate": 4.9,
        "customer_name": "Heather Le",
        "service_type": "Garage Door Off Track",
        "tech_name": "Sharon H.",
        "review": "I would recommend them to anyone needing garage door services. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-04-10",
        "review_rate": 5.0,
        "customer_name": "Sandra Martinez",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Jennifer B.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-02-15",
        "review_rate": 5.0,
        "customer_name": "Janet Mcmillan",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Joseph C.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-08-28",
        "review_rate": 4.9,
        "customer_name": "Eric Frazier",
        "service_type": "Garage Door Repair",
        "tech_name": "Nicole R.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-05-20",
        "review_rate": 4.9,
        "customer_name": "Dale Maldonado",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Clarence M.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-12-05",
        "review_rate": 4.9,
        "customer_name": "Sean Stone",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Adrian R.",
        "review": "I can't recommend this garage door service enough after my experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-11-21",
        "review_rate": 4.9,
        "customer_name": "Richard Davis",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Monica N.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-02-09",
        "review_rate": 4.9,
        "customer_name": "Patricia Jackson",
        "service_type": "Garage Door Insulation",
        "tech_name": "Scott J.",
        "review": "This company has set the standard for garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-05-12",
        "review_rate": 4.9,
        "customer_name": "Alyssa Blake",
        "service_type": "Garage Door Opener",
        "tech_name": "Christian N.",
        "review": "My experience with their garage door service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-08-05",
        "review_rate": 4.9,
        "customer_name": "Lorraine Lee",
        "service_type": "Garage Door Opener",
        "tech_name": "Timothy S.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-08-31",
        "review_rate": 4.8,
        "customer_name": "Frank Davidson",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Gary A.",
        "review": "This company has set the standard for garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-04-19",
        "review_rate": 4.9,
        "customer_name": "Corey Carey",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Joseph F.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-03-22",
        "review_rate": 4.9,
        "customer_name": "Elizabeth Yoder",
        "service_type": "Garage Door Services",
        "tech_name": "Janice B.",
        "review": "The technician was friendly and professional at all times. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-10-03",
        "review_rate": 4.9,
        "customer_name": "Kevin Sanchez",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Richard W.",
        "review": "I had an emergency with my garage door, and they responded quickly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-08-09",
        "review_rate": 4.8,
        "customer_name": "Meagan Smith",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Andrea S.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-05-06",
        "review_rate": 4.9,
        "customer_name": "Alicia Murphy",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Donald R.",
        "review": "I can't believe how much better my garage door operates now. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-11-06",
        "review_rate": 4.9,
        "customer_name": "Jasmine Bond",
        "service_type": "Garage Door Installation",
        "tech_name": "Cynthia V.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-12-30",
        "review_rate": 4.8,
        "customer_name": "Justin Thomas",
        "service_type": "Garage Door Insulation",
        "tech_name": "Tina M.",
        "review": "I highly value the quick turnaround for my garage door repairs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-06-12",
        "review_rate": 5.0,
        "customer_name": "Jonathan Robbins",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Kenneth W.",
        "review": "I would rate their service as the best I've ever had for garage work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-09-30",
        "review_rate": 4.8,
        "customer_name": "Joshua Johnston",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Janice H.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-10-25",
        "review_rate": 4.9,
        "customer_name": "Stephanie Woods",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Stephanie W.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-11-08",
        "review_rate": 4.8,
        "customer_name": "Seth Stewart",
        "service_type": "Garage Door Off Track",
        "tech_name": "Reginald G.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-05-22",
        "review_rate": 4.9,
        "customer_name": "Denise Miller",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Justin G.",
        "review": "I was surprised by how fast the technician completed the work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-07-09",
        "review_rate": 4.9,
        "customer_name": "Andre Larson",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Stacy H.",
        "review": "I appreciate how they kept me informed throughout the repair process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-03-17",
        "review_rate": 4.9,
        "customer_name": "Emma Colon",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Chelsea H.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-03-16",
        "review_rate": 4.9,
        "customer_name": "Laurie Phillips",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Ashley W.",
        "review": "I highly value the quick turnaround for my garage door repairs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-04-05",
        "review_rate": 4.8,
        "customer_name": "Mr. Clarence Ford MD",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Kimberly M.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-12-04",
        "review_rate": 4.9,
        "customer_name": "Janice Roth",
        "service_type": "Garage Door Services",
        "tech_name": "Ryan S.",
        "review": "I loved how the team worked seamlessly to get the job done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-10-01",
        "review_rate": 4.9,
        "customer_name": "Edward Baker",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Trevor J.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-02-02",
        "review_rate": 4.9,
        "customer_name": "David Wright",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Chase R.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-09-19",
        "review_rate": 4.9,
        "customer_name": "Mr. Gregory Munoz MD",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Craig T.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-05-29",
        "review_rate": 4.9,
        "customer_name": "Stephanie Hurley",
        "service_type": "Garage Door Off Track",
        "tech_name": "Jennifer T.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-02-27",
        "review_rate": 4.9,
        "customer_name": "Mark Williams",
        "service_type": "Garage Door Off Track",
        "tech_name": "Christina G.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-08-28",
        "review_rate": 4.9,
        "customer_name": "Peter Morrow",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "David B.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-05-28",
        "review_rate": 4.8,
        "customer_name": "Paige Alvarez DVM",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Juan G.",
        "review": "The service was efficient and exceeded my expectations. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-08-12",
        "review_rate": 4.9,
        "customer_name": "Donna Blake",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Joshua C.",
        "review": "The follow-up service was just as excellent as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-12-29",
        "review_rate": 4.8,
        "customer_name": "Patricia Payne",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Joseph S.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-02-28",
        "review_rate": 4.8,
        "customer_name": "John Robbins",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Alicia W.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-07-19",
        "review_rate": 5.0,
        "customer_name": "Dakota Stevenson",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Kelly M.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-01-05",
        "review_rate": 5.0,
        "customer_name": "Dr. William Edwards",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Anthony G.",
        "review": "I can't believe how much better my garage door operates now. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-02-17",
        "review_rate": 5.0,
        "customer_name": "Adam Baker",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Timothy W.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-09-01",
        "review_rate": 5.0,
        "customer_name": "Michelle Hawkins",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Jeffrey M.",
        "review": "This company has set the standard for garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-05-13",
        "review_rate": 4.9,
        "customer_name": "Victoria Gutierrez",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Deborah F.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-06-29",
        "review_rate": 4.9,
        "customer_name": "Patricia Brown",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Danny G.",
        "review": "I was thoroughly impressed with their garage door safety inspection. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-01-08",
        "review_rate": 4.9,
        "customer_name": "Curtis Mcpherson",
        "service_type": "Garage Door Repair",
        "tech_name": "Jonathan B.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-05-21",
        "review_rate": 5.0,
        "customer_name": "Allison Mcdonald",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Robin A.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-10-08",
        "review_rate": 4.9,
        "customer_name": "Megan Black",
        "service_type": "Garage Door Services",
        "tech_name": "Rebecca M.",
        "review": "I would recommend them to anyone needing garage door services. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-05-19",
        "review_rate": 4.9,
        "customer_name": "David Reed",
        "service_type": "Garage Door Opener",
        "tech_name": "Tiffany P.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-01-02",
        "review_rate": 4.8,
        "customer_name": "Cindy Perez",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Melinda J.",
        "review": "The follow-up service was just as excellent as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-09-29",
        "review_rate": 4.9,
        "customer_name": "Vanessa Allen",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Angela H.",
        "review": "I couldn\u2019t be more satisfied with my garage door experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-11-12",
        "review_rate": 4.9,
        "customer_name": "Jennifer Johnson",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Wendy S.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-04-30",
        "review_rate": 4.9,
        "customer_name": "Yvonne Miller",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Michael T.",
        "review": "This service was exactly what I needed for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-07-21",
        "review_rate": 4.9,
        "customer_name": "Jennifer Hicks",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Priscilla K.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-04-01",
        "review_rate": 4.8,
        "customer_name": "Michael Dawson",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Joshua G.",
        "review": "My garage door never worked better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-03-06",
        "review_rate": 4.9,
        "customer_name": "Jeffrey Rocha",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Wendy D.",
        "review": "I was surprised by how fast the technician completed the work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-03-13",
        "review_rate": 4.8,
        "customer_name": "Mark Phelps",
        "service_type": "Garage Door Insulation",
        "tech_name": "Rachel A.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-07-14",
        "review_rate": 4.8,
        "customer_name": "Michelle Stone",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Richard W.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-04-25",
        "review_rate": 4.9,
        "customer_name": "Evelyn Morris",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "James P.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-07-09",
        "review_rate": 4.9,
        "customer_name": "Gerald Ortiz",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Jeremy L.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-10-03",
        "review_rate": 4.9,
        "customer_name": "Samantha Jones",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Steven M.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-04-02",
        "review_rate": 4.9,
        "customer_name": "David House",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Brenda B.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-01-24",
        "review_rate": 4.8,
        "customer_name": "Sarah Johnson",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Samantha C.",
        "review": "I would recommend them to anyone needing garage door services. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-01-10",
        "review_rate": 4.9,
        "customer_name": "Kevin Goodwin",
        "service_type": "Garage Door Repair",
        "tech_name": "Matthew R.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-10-27",
        "review_rate": 4.9,
        "customer_name": "Robert Hendricks",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Jackson S.",
        "review": "I couldn\u2019t be more satisfied with my garage door experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-03-28",
        "review_rate": 4.9,
        "customer_name": "Stacie Torres",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Loretta W.",
        "review": "The follow-up service was just as excellent as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-01-28",
        "review_rate": 4.9,
        "customer_name": "Diana Nunez",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Shari B.",
        "review": "The service exceeded my expectations in every way. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-08-12",
        "review_rate": 5.0,
        "customer_name": "Tammy Murphy",
        "service_type": "Garage Door Opener",
        "tech_name": "James S.",
        "review": "I was grateful for their quick response to my garage door emergency. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-10-18",
        "review_rate": 4.9,
        "customer_name": "Jack Jacobs",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Janet S.",
        "review": "The technician was courteous and very knowledgeable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-03-15",
        "review_rate": 4.8,
        "customer_name": "Robert Washington",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Veronica B.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-02-22",
        "review_rate": 4.9,
        "customer_name": "Kathleen Miller",
        "service_type": "Garage Door Off Track",
        "tech_name": "Kimberly S.",
        "review": "I found their approach to customer service refreshing and effective. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-09-10",
        "review_rate": 4.8,
        "customer_name": "Carl Davis",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Robert C.",
        "review": "My experience with their garage door service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-05-17",
        "review_rate": 4.9,
        "customer_name": "Joseph Kerr",
        "service_type": "Garage Door Installation",
        "tech_name": "Spencer A.",
        "review": "I had an emergency with my garage door, and they responded quickly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-11-13",
        "review_rate": 4.8,
        "customer_name": "Brian Rodriguez",
        "service_type": "Garage Door Opener",
        "tech_name": "Nicole P.",
        "review": "I was surprised by how fast the technician completed the work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-06-12",
        "review_rate": 5.0,
        "customer_name": "Nicole Gallegos",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Kristina C.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-11-30",
        "review_rate": 4.8,
        "customer_name": "Maurice Nunez",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Scott S.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-07-30",
        "review_rate": 4.9,
        "customer_name": "Lisa Wang",
        "service_type": "Garage Door Repair",
        "tech_name": "James M.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-11-25",
        "review_rate": 5.0,
        "customer_name": "Allison Serrano",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Thomas S.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-08-06",
        "review_rate": 4.9,
        "customer_name": "Cody Mays",
        "service_type": "Garage Door Repair",
        "tech_name": "Lydia S.",
        "review": "My experience with their garage door service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-01-31",
        "review_rate": 4.9,
        "customer_name": "Amanda Rivas",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Danielle W.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-02-29",
        "review_rate": 5.0,
        "customer_name": "Brian Wallace",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Sergio G.",
        "review": "I can't express how satisfied I am with the garage door installation process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-05-29",
        "review_rate": 4.8,
        "customer_name": "Tiffany Anderson",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Linda M.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-05-01",
        "review_rate": 5.0,
        "customer_name": "Edward Fitzgerald",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Roy J.",
        "review": "I loved how the team worked seamlessly to get the job done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-09-20",
        "review_rate": 4.9,
        "customer_name": "Ashley Mejia",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Robin K.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-09-28",
        "review_rate": 4.8,
        "customer_name": "Stephen Johnson",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Barbara P.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-10-18",
        "review_rate": 4.9,
        "customer_name": "Stephanie Howard",
        "service_type": "Garage Door Services",
        "tech_name": "Samantha S.",
        "review": "My experience with their garage door service was overwhelmingly positive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-06-29",
        "review_rate": 4.8,
        "customer_name": "Taylor Green",
        "service_type": "Garage Door Off Track",
        "tech_name": "David S.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-08-21",
        "review_rate": 4.9,
        "customer_name": "Randy Williams",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Joel L.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    }
]
export default LocationReviewsData;
